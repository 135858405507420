import axios from '../adapter';

const request = Object.freeze({
  login: '/login',
});

export const UserAgent = {
  login: (login:string, pass:string) => axios({
    method: 'post',
    url: request.login,
    data: {
      email: login,
      password: pass
    }
  }),
}