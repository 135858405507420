const routes = Object.freeze({
  path: {
    instantSearch: '/properties',
    propertySingle: '/marketing/:id'
  },
  ajax: {
    //instantSearch: '/properties/instant_search',
    instantSearch: '/self-serve/properties/general-inventory',
    propertySingle: 'self-serve/show-property/',
    propertyAvailabilities: 'self-serve/show-property/available-inventory',
    emailAvailabilities: 'self-serve/email-oscar-results',
    validateCode: 'self-serve/validate-promo-code'
  }
})

export default routes;