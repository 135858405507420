import { observable, action, runInAction, computed } from "mobx";
import { InquiryAgent } from "./agent";
import { iRequestState, iRequestInfoData } from "./interfaces";
import { routes } from "./routes";
import moment from "moment";
import { RootStore } from "../RootStore";
import { iProperty } from "../Property/interfaces";
import { persist } from 'mobx-persist';
export class RequestInfo {
    
    protected RootStore:RootStore;

    constructor(RootStore: RootStore){
        this.RootStore = RootStore;
    }
    
    @persist('object') @observable
    requestState: iRequestState = {
        full_name: "",
        mobile_number: "",
        email: "",
        housekeeping: "",
        bedroom: "",
        property_id: 0,
        pets: "",
        num_pets: "",
        pet_details: "",
        other_details: "",
        arrival_date: undefined,
        departure_date: undefined,
        monthly_budget: "",
        property: null
    }

    @persist('object') @observable
    ui = {
        showAdditionalFeesModal: false,
        requiredRequestInfoFields: ["full_name", "email", "mobile_number", "housekeeping", "pets"],
        validationErrors: [],
    }

    @action.bound
    resetModel() {
        this.requestState.full_name = "";
        this.requestState.mobile_number = "";
        this.requestState.email = "";
        this.requestState.housekeeping = "";
        this.requestState.bedroom = "";
        this.requestState.property_id = 0;
        this.requestState.pets = "";
        this.requestState.num_pets = "";
        this.requestState.pet_details = "";
        this.requestState.other_details = "";
        this.requestState.arrival_date = "";
        this.requestState.departure_date = "";
        this.requestState.monthly_budget  ="";
        this.requestState.property = null;
        this.ui.showAdditionalFeesModal = false;
        this.ui.requiredRequestInfoFields = ["full_name", "email", "mobile_number", "housekeeping", "pets"];
        this.ui.validationErrors = [];
    }

    /**
     * Toggle visibility for additional fees modal
     */
    @action.bound
    toggleAdditionalFeesVisibility() {
        this.ui.showAdditionalFeesModal = !this.ui.showAdditionalFeesModal;
    }

    /**
     * Submit Customize data
     */
    @action.bound
    navigateToInquiry(routerHistory: any, property: iProperty) {
        console.log("!");
        this.requestState.property = property;
        this.updateInquiryId(1);
        const fees: Array<string> = [];
        routerHistory.push(`${routes.path.guestInfo}/${this.RootStore.propertyModel.property.id}`);
    }

    // @action.bound
    // navigateToConfirmation(routerHistory: any, option_id: number) {
    //     console.log("!");
    //     console.log(option_id);
    //     this.updateInquiryId(2);
    //     const fees: Array<string> = [];
    //     console.log(`navigating to ${routes.path.guestConfirmation}/${this.requestState.property.id}`);
    //     routerHistory.push(`${routes.path.guestConfirmation}/${this.requestState.id}`);
    // }

    @action.bound
    updateInquiryId(id:any) {
        this.requestState.id = id;
    }

    /**
     * Submit Guest Info data
     */
    @action.bound
    submitInquiryInfo(routerHistory: any): Promise<void> | undefined {
        this.ui.requiredRequestInfoFields.forEach((field) => this.validateField(field));
        if (this.ui.validationErrors.length > 0) {
			// console.log("I am returning");
            return;
        }
        this.requestState.arrival_date = this.getArrivalDate;
        this.requestState.departure_date = this.getDepartureDate;

        // console.log("this.RootStore.propertyModel.property.id", this.RootStore.propertyModel.property.id);
        
        // routerHistory.push(`${routes.path.guestConfirmation}/${this.requestState.property.id}`);
        // window.scrollTo(0, 0);

        const propertyId = this.RootStore.propertyModel.property.id;

        const params = {
            full_name: this.requestState.full_name,
            mobile_number: this.requestState.mobile_number,
            email: this.requestState.email,
            housekeeping: this.requestState.housekeeping,
            bedroom: this.requestState.bedroom,
            property_id: propertyId,
            pets: this.requestState.pets,
            num_pets: this.requestState.num_pets,
            pet_details: this.requestState.pet_details,
            other_details: this.requestState.other_details,
            arrival_date: this.requestState.arrival_date,
            departure_date: this.requestState.departure_date,
            monthly_budget: this.requestState.monthly_budget,
            promo_code: this.RootStore.searchModel.filters.promo_code
        }
		
        if (propertyId) {

            return InquiryAgent.misc.submit(propertyId, params)
                .then(resp => {
                    routerHistory.push(`${routes.path.guestConfirmation}/${propertyId}`);
                    window.scrollTo(0, 0);
                })
                .catch((error) => {
                    console.log("submitInquiryInfo error", error);
                })
        }
    }
    /**
     * Update value when input changes
     */
    @action.bound
    updateValue(valueName: string, value: any) {
        if (valueName in this.requestState) {
            (this.requestState as any)[valueName] = value;
            this.validateField(valueName);
        }
    }
    
    @action.bound
    updatePets(valueName: string, value: any) {
        if (valueName in this.requestState) {
            (this.requestState as any)[valueName] = value;
            // TODO: // Fix this garbage
            if ( value === "Yes" ) {
                this.ui.requiredRequestInfoFields.push("pet_details", "num_pets");
            } else {
                const index = this.ui.requiredRequestInfoFields.findIndex((item) => item === "pet_details");
                if (index > -1) {
                    this.ui.validationErrors.splice(index, 1);
                }
                const index2 = this.ui.requiredRequestInfoFields.findIndex((item) => item === "num_pets");
                if (index2 > -1) {
                    this.ui.validationErrors.splice(index2, 1);
                }
            }
            this.validateField(valueName);
        }
	}
	

    @action.bound
    validateField(fieldName: string) {
        const emailRule = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;


        const phoneRule = /^(?:(?:(\s*\(?([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\)?\s*(?:[.-]\s*)?)([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})/;
        // if (fieldName === "email_confirmation" || fieldName === "email") {
        //     if (this.requestState.email !== this.requestState.email_confirmation) {
        //         if (!this.requestState.validationErrors.includes("email_confirmation")) {
        //             this.requestState.validationErrors.push("email_confirmation");
        //         }
        //     } else {
        //         if (this.requestState.validationErrors.includes("email_confirmation")) {
        //             const index = this.requestState.validationErrors.findIndex((item) => item === "email_confirmation");
        //             this.requestState.validationErrors.splice(index, 1);
        //         }
        //     }
        // }

        const fieldValue = (this.requestState as any)[fieldName];
        if (fieldName === "email") {
            if (!emailRule.test(fieldValue.toString().toLowerCase())) {
                if (!this.ui.validationErrors.includes("email")) {
                    this.ui.validationErrors.push("email");
                }
            } else {
                const index = this.ui.validationErrors.findIndex((item) => item === "email");
                if (index > -1) {
                    this.ui.validationErrors.splice(index, 1);
                }
            }
        } else if ( fieldName === "mobile_number" ) {
            if ( !phoneRule.test(fieldValue.toString().toLowerCase()) ) {
                if (!this.ui.validationErrors.includes("mobile_number")) {
                    this.ui.validationErrors.push("mobile_number");
                }
            } else {
                const index = this.ui.validationErrors.findIndex((item) => item === "mobile_number");
                if (index > -1) {
                    this.ui.validationErrors.splice(index, 1);
                }
            }
        } else {
            if (this.ui.requiredRequestInfoFields.includes(fieldName))
            {
                if (!fieldValue) {
                    if (!this.ui.validationErrors.includes(fieldName)) {
                        this.ui.validationErrors.push(fieldName);
                    }
                } else {
                    const index = this.ui.validationErrors.findIndex((item) => item === fieldName);
                    if (index > -1) {
                        this.ui.validationErrors.splice(index, 1);
                    }
                }
            }
        }
    }
    //TODO: // Put this in the top most model
    @computed
    get getArrivalDate() {
      const searchFilters = {...this.RootStore.searchModel.filters};
      const arrival = !!searchFilters && searchFilters.arrival_date && moment(searchFilters.arrival_date).format('Y-MM-DD') || moment().format('Y-MM-DD');
      //const arrival = !!searchFilters && searchFilters.arrival_date;
      return arrival;
    }
   //TODO: // Put this in the top most model
    @computed
    get getDepartureDate() {
    const searchFilters = {...this.RootStore.searchModel.filters};
      const departure = !!searchFilters && searchFilters.departure_date && moment(searchFilters.departure_date).format('Y-MM-DD') || moment().format('Y-MM-DD');
      //const departure = !!searchFilters && searchFilters.departure_date;
      return departure;
    }
}
