import { observable, action, computed, runInAction } from "mobx";
import { Properties } from "./Search/agent";
import { RootStore } from "../RootStore";
import { iProperty } from "./interfaces";
import { iQueryParams } from "./Search/interfaces";
import { iNeighbor } from "./interfaces";
import moment from "moment";
import { persist } from 'mobx-persist'
// @todo FIXME dirty hack needs to be removed

function generateRandomPrice(min: number, max: number) {
    return (Math.round(Math.random() * (max - min) + min) * 10) / 10; // JS... old good JS
}

// @todo FIXME dirty hack needs to be removed


export class PropertiesList {

    protected RootStore: RootStore;
    private initialTime = Date.now();

    constructor(RootStore: RootStore) {
        this.RootStore = RootStore;
    }

    /**
     *
     * @type {number}
     */
    protected requestTime: number | null = 0;
    protected lastRequestData = {
        lat: <number | string>0,
        long: <number | string>0,
        leadIdNeeded: true,
    }
    protected lead_id: number | null = null;


    @persist('list') @observable
    propertyList: iProperty[] = [];

    @persist('object') @observable
    ui = {
        loading: true,
    }

    @persist('list') @observable
    neighbors: iNeighbor[] | [] = [];
    /**
     * Sets loading flag for search results
     * @param {boolean} val
     */
    @action.bound
    resetModel() {
        this.propertyList = [];
        this.ui.loading = true;
        this.neighbors = [];
    }



    @action.bound
    updateLoading(val: boolean) {
        this.ui.loading = val;
        if (val) {
            this.requestTime = Date.now();
        } else {
            this.requestTime = null;
        }
    }

    @action
    instantSearch(params: any, withoutLeadId?: boolean) {
        this.propertyList = []
        this.updateLoading(true);
        if (withoutLeadId) {
            delete params.lead_id;
        }
        delete params.lead_id;
        params.bookingParams = {
            full_name: this.RootStore.bookingModel.bookingState.full_name,
            promo_code: this.RootStore.bookingModel.bookingState.promo_code,
            email: this.RootStore.bookingModel.bookingState.email,
            pets: this.RootStore.bookingModel.bookingState.pets,
            parking_type: this.RootStore.bookingModel.bookingState.parking_type,
            number_parking_spots: this.RootStore.bookingModel.bookingState.number_parking_spots,
            charge_parking_to_guest: this.RootStore.bookingModel.bookingState.charge_parking_to_guest,
            number_additional_adults: this.RootStore.bookingModel.bookingState.number_additional_adults,
            number_additional_children:this.RootStore.bookingModel.bookingState.number_additional_children,
            total_number_of_additional_occupants: this.RootStore.bookingModel.bookingState.total_number_of_additional_occupants,
            occupants_notes: this.RootStore.bookingModel.bookingState.occupants_notes
        }
        params.searchParams = {
            searchedAddress: this.RootStore.searchModel.filters.address
        }
        return Properties.getAll(params)
            .then(resp => {

                const { data } = resp.data;
                // const optionsExist = !!data.items.filter((i: any) => Array.isArray(i.options) && i.options.length).length;
                // const ratesExist = !!data.items.filter((i: any) => Array.isArray(i.rates) && i.options.rates.length).length;

                let newdata;
                if ( !Array.isArray(data.items)) {
                    newdata = Object.values(data.items);
                    data.items = newdata;
                }

                this.lastRequestData.lat = params.lat;
                this.lastRequestData.long = params.long;

                if (params.lead_id) {
                    this.lastRequestData.leadIdNeeded = false;
                    return this.instantSearch(params, true);
                }

                if (!data.items.length && params.lead_id) {
                    setTimeout(() => {
                        this.lastRequestData.leadIdNeeded = true;
                        return this.instantSearch(params);
                    }, 90000);
                    return {};
                }

                runInAction(() => {
                    // this.propertyList = data.items.filter(item => (item.options && item.options.length > 0) || (item.rate_ranges && item.rate_ranges.length > 0));
                    this.propertyList = data.items.filter(item => (item.options && item.options.length > 0) || item);
                    this.RootStore.searchModel.ui.showOscar = data.show_oscar;

                    // console.log("data.offset", data.offset);
                    // console.log("data.limit", data.limit);
                    // console.log("data.total", data.total);
                    
                    
                    //this.RootStore.searchModel.filters.page = (data.offset / data.limit) + 1;
                    
                    
                    
                    this.RootStore.searchModel.filters.total = data.total;
                    if (this.RootStore && data.items.length) {
                        // this.propRootStore.searchModel.filters.rate.value.min = lowestInOptions < lowestInRates ? lowestInOptions : lowestInRates;
                        // this.propRootStore.searchModel.filters.rate.value.max = highestInOptions > highestInRates ? highestInOptions : highestInRates;
                    }
                });
                this.updateLoading(false);
                return data;
            })
            .catch(err => { console.log('[property list] error retrieving properties'); return err; })
    }

    @action
    clearPropertyList() {
        // console.log("clearPropertyList")
        this.propertyList = [];
        // console.log("clearPropertyList  this.propertyList",  this.propertyList);
    }
    /**
     * Makes request to server to get property list according to input parameters
     * @filters {object} property filters
     * @returns {void}
     */
    @action
    updatePropertyList(filters: iQueryParams) {

        //console.log("updatePropertyList filters", filters);

        const search = (params, leadIdNeeded?: boolean) => {
            this.initialTime = Date.now();
            return this.instantSearch(params, leadIdNeeded);
        }
        const params = JSON.parse(JSON.stringify(filters));
        return search(params, !this.lastRequestData.leadIdNeeded);




        // console.log("updatePropertyList params", params);
        // if (this.lastRequestData.lat != params.lat || this.lastRequestData.long != params.long) {
        //   const requestDataParams = {
        //     arrival_date: filters.arrival_date || moment().format('Y-MM-DD'),
        //     departure_date: filters.departure_date || moment().add(30, "days").format('Y-MM-DD'),
        //     city_name: filters.city_name,
        //     state_name: filters.state_name,
        //     country_name: filters.country_name,
        //     lat: filters.lat,
        //     long: filters.long,
        //     bedroom: Array.isArray(filters.apartmentTypes)
        //     && filters.apartmentTypes.map((ut:any) => { console.log(ut); ut.name}).join(', ')
        //     || 'Studio, 1 Bedroom, 2 Bedroom, 3 Bedroom',
        //     promoCode: filters.promoCode
        //   }

        //   return Properties.searchRequest(requestDataParams)
        //     .then((res) => {
        //       if (res.data) {
        //         this.lead_id = res.data.data;
        //       }
        //       params.lead_id = this.lead_id;
        //       return search(params);
        //     });
        // } else {
        //   params.lead_id = this.lead_id;
        //   return search(params, !this.lastRequestData.leadIdNeeded);
        // }
    }
}