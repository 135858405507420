import { observable, action, runInAction } from "mobx";
import { BookingAgent } from "./agent";
import { iBookingState, iGuestInfoData, iBillingInfoData, iPaymentDetails, iRentalAgreementData, iPetsData, iParkingData } from "./interfaces";
import { routes } from "./routes";
import { RootStore } from "../RootStore";
import { persist } from 'mobx-persist';
import Pusher from 'pusher-js';
import { argv0 } from "process";

export class Booking {
    
    protected RootStore:RootStore;
    protected pusher: any;
    protected channel: any;

    constructor(RootStore: RootStore){
        this.RootStore = RootStore;

        // this.pusher = new Pusher("e6099aa4f9fe130e22dc", {
        //     cluster: 'us3',
        // });
    }
    
    @persist('object') @observable
    bookingState: iBookingState = {
        id: 0,
        country: "",
        title: "",
        full_name: "",
        company: "",
        mobile_number: "",
        city: "",
        state: "",
        email: "",
        email_confirmation: "",
        address: "",
        address_2: "",
        zip: "",
        client_file_no: "",

        cc_billing_address: "",
        cc_address_2: "",
        cc_city: "",
        cc_state: "",
        cc_zip: "",
        cc_country: "",
        cc_ref_id: "",

        promo_code: "",
        parking_type: "",
        number_parking_spots: undefined,
        charge_parking_to_guest: false,
        number_additional_adults: "",
        number_additional_children: "",
        total_number_of_additional_occupants: "",
        occupants_notes: "",

        ip_address: "",
        vacate_policy: false,
        cancellations: false,
        renters_insurance: false,
        signature: "",
        selectedOption: undefined,
        additionalFees: [],
        taxes: [],
        pets: new Array(),
        rental_signature_full_name: "",
        validPromoCode: false,
        ezpay_logs: {},
        show_pet_error: false,
        drawbacks: false,
        show_dates_error: false,
        star_rating: false,
        no_smoking: false,

        // FIXME: should be received from Search store
        // option_id: 3870,
        validationErrors: [],
        searchValidationErrors: [],
        requiredGuestInfoFields: ["full_name", "mobile_number", "email", "address", "city", "state", "country", "zip",
        "cc_billing_address", "cc_city", "cc_state", "cc_zip"],
        requiredBillingInfoFields: ["cc_billing_address", "cc_city", "cc_state", "cc_zip", "cc_country"],
        requiredRentalAgreementFields: ["vacate_policy", "cancellations", "signature", "star_rating", "rental_signature_full_name"],
        requiredSearchInfoFields: ["full_name", "email", "promo_code"],

        convertedCurrencyAmount: 0,
        fee_total: 0,
        tax_total: 0,
        total: 0,
        total_day_rate: 0,
        client_file_number: "",
        company_name: ""
    }

    @persist('object') @observable
    paymentDetails: iPaymentDetails = {
        card_number: "",
        name: "",
        expiration_month: "",
        expiration_year: "",
        security_code: "",
        agreed: false,
        validationErrors: []
    }

    @persist('object') @observable
    ui = {
        showAdditionalFeesModal: false,
        userDeniedCustomization: false,
        userAppliedCustomizations: false,
        showBookNowConfirmationModal: false,
        userConfirmedBooking: false,
        routerHistory: "",
        validPromoCode: false
    }

    @action.bound
    resetModel() {
        this.ui.showAdditionalFeesModal = false;
        this.ui.userDeniedCustomization = false;
        this.ui.userAppliedCustomizations = false;
        this.ui.showBookNowConfirmationModal =false;
        this.ui.routerHistory = "";
        this.paymentDetails.card_number = "";
        this.paymentDetails.name = ""
        this.paymentDetails.expiration_month = "";
        this.paymentDetails.expiration_year = "";
        this.paymentDetails.security_code = "";
        this.paymentDetails.agreed = false;
        this.paymentDetails.validationErrors = []
        this.bookingState.id =  0;
        this.bookingState.country =  "";
        this.bookingState.title = "";
        this.bookingState.full_name = "";
        this.bookingState.company = "";
        this.bookingState.mobile_number = "";
        this.bookingState.city = "";
        this.bookingState.state = "";
        this.bookingState.email = "";
        this.bookingState.email_confirmation = "";
        this.bookingState.address = "";
        this.bookingState.address_2 = "";
        this.bookingState.zip = "";

        this.bookingState.promo_code = "";
        this.bookingState.pets = [];
        this.bookingState.parking_type = "";
        this.bookingState.number_parking_spots = undefined;
        this.bookingState.charge_parking_to_guest = false;

        this.bookingState.number_additional_adults = "";
        this.bookingState.number_additional_children = "";
        this.bookingState.total_number_of_additional_occupants = "";
        this.bookingState.occupants_notes = "";

        this.bookingState.cc_billing_address = "";
        this.bookingState.cc_address_2 = "";
        this.bookingState.cc_city = "";
        this.bookingState.cc_state = "";
        this.bookingState.cc_zip = "";
        this.bookingState.cc_country = "";

        this.bookingState.vacate_policy = false;
        this.bookingState.cancellations = false;
        this.bookingState.renters_insurance = false;
        this.bookingState.signature = "";
        this.bookingState.selectedOption = undefined;
        this.bookingState.additionalFees = [];
        this.bookingState.taxes = [];
        this.bookingState.client_file_no = "";
        this.bookingState.show_pet_error = false;
        this.bookingState.show_dates_error = false;
        this.bookingState.drawbacks = false;
        this.bookingState.star_rating = false;
        this.bookingState.no_smoking = false;
        // FIXME: should be received from Search store
        // option_id: 3870,
        this.ui.validPromoCode = false;
        this.bookingState.validationErrors = [];
        this.bookingState.searchValidationErrors = [];
        this.bookingState.requiredGuestInfoFields = ["full_name", "mobile_number", "email", "address", "city", "state", "country", "zip",
        "cc_billing_address", "cc_city", "cc_state", "cc_zip"];
        this.bookingState.requiredBillingInfoFields = ["cc_billing_address", "cc_city", "cc_state", "cc_zip", "cc_country"];
        this.bookingState.requiredRentalAgreementFields = ["vacate_policy", "cancellations", "signature", "star_rating", "no_smoking", "rental_signature_full_name"];
        
        
        this.bookingState.requiredSearchInfoFields = ["full_name", "email", "promo_code"];

        this.bookingState.convertedCurrencyAmount = 0;
        this.bookingState.rental_signature_full_name = "";
        this.bookingState.ezpay_logs = {};
        this.bookingState.fee_total = 0;
        this.bookingState.tax_total = 0;
        this.bookingState.total = 0;
        this.bookingState.total_day_rate = 0;
        this.bookingState.client_file_number = "";
        this.bookingState.company_name = "";
    }

    /**
     * Toggle visibility for additional fees modal
     */
    @action.bound
    toggleAdditionalFeesVisibility() {
        this.ui.showAdditionalFeesModal = !this.ui.showAdditionalFeesModal;
    }

    @action.bound
    toggleBookNowModel( routerHistory: any = null) {
        this.ui.routerHistory = routerHistory;
        this.ui.showBookNowConfirmationModal = !this.ui.showBookNowConfirmationModal;
    }

    @action.bound
    toggleConfirmedBooking() {
        this.ui.userConfirmedBooking = !this.ui.userConfirmedBooking;
    }

    @action.bound
    setDatesError(bool) {
        this.bookingState.show_dates_error = bool;
    }

    /**
     * Toggle visibility for additional fees modal
     */
    @action.bound
    toggleNeedsCustomizations() {
        ("toggleNeedsCustomizations");
        this.ui.userDeniedCustomization = !this.ui.userDeniedCustomization;
        if ( this.ui.userDeniedCustomization ) {
            this.bookingState.additionalFees.forEach((item) => item.applied = item.checked = false);
            // this.getTotalWithTaxes();
        }
    }

    /**
     * Add or remove additional fee by name
     */
    @action.bound
    checkAdditionalFee(name: string, add: boolean) {
        const fee = this.bookingState.additionalFees.find((item) => item.name === name);

        if (!!fee) {
            fee.checked = add;
            if ( this.ui.userDeniedCustomization ) {
                this.ui.userDeniedCustomization = false;
            }
        }
    }

    @action.bound
    setUserAcceptBooking( bool: boolean) {
        this.ui.userConfirmedBooking = bool;
        if ( this.ui.userConfirmedBooking ) {
            this.submitPaymentDatails(this.ui.routerHistory)
        } else {
            this.toggleBookNowModel();
        }
    }


    @action
    setCustomizations( bool: boolean) {
        this.ui.userDeniedCustomization = bool;
    }

    /**
     * Clear additional fees
     */
    @action.bound
    clearAdditionalFees() {
        console.log("clearAdditionalFees");
        if ( this.ui.userDeniedCustomization ) {
            this.ui.userDeniedCustomization = false;
        }
        this.bookingState.additionalFees.forEach((item) => item.applied = item.checked = false);
    }

    /**
     * Apply additional fees
     */
    @action.bound
    applyAdditionalFees() {
        // console.log("applyAdditionalFees");
        this.bookingState.additionalFees.forEach((item) => item.applied = item.checked);
       // this.getTotalWithTaxes();
    }

    @action.bound
    setAdditionalFees(fees: any ) {
        // console.log("setAdditionalFees");
        this.bookingState.additionalFees = fees || [];
        this.ui.userAppliedCustomizations = false;
        this.clearAdditionalFees();
    }

    @action.bound
    setCCRefId( id: string = "0" ) {
        this.bookingState.cc_ref_id = id
    }

    @action.bound
    setPromoCode( code: string) {
        this.bookingState.promo_code = code;
        this.ui.validPromoCode = true;
    }

    @action.bound
    applySearchForm() {
        this.bookingState.requiredSearchInfoFields.forEach((field) => this.validateSearchField(field));
        if ( !this.ui.validPromoCode || (this.bookingState.searchValidationErrors.length > 0) ) {
            return;
        } else {
            this.RootStore.uiModel.togglePromoCodeVisibility();
        }
    }

    @action.bound
    setTotalOccupants( total: any ) {
        this.bookingState.total_number_of_additional_occupants = total;
    }

    @action.bound
    addPet(){
        const newPet: iPetsData = {
            category: "",
            breed: "",
            weight: 0,
            chargeToGuest: false,
            weight_units: "Lbs"
        }
        this.bookingState.pets.push(newPet);
        //console.log("this.bookingState.pets", this.bookingState.pets)
    }

    @action.bound
    removePet(index){
        this.RootStore.bookingModel.bookingState.pets.splice(index, 1);
    }

    @action.bound
    clearPets(){
        this.bookingState.pets = [];
    }

    @action.bound
    setPets(pets){
        this.bookingState.pets = pets;
    }


    @action.bound
    updatePetValue(valueName: string, index: any, value: any) {
        const petArray = [...this.bookingState.pets];
        const petObject = {...petArray[index]}
        petObject[valueName] = value;

        const petsToExclude = ["Pit Bull", "German Shepherd Dog"];

        if ( petsToExclude.includes(value) ) {
            this.bookingState.show_pet_error = true;
        } else {
            this.bookingState.show_pet_error = false;
        }

        if ( valueName === "category" && value === "Cat" ) {
            petObject['breed'] = "Other";
        }
        
        petArray[index] = petObject;
        
        this.bookingState.pets = petArray;
	}

    @action.bound
    setConvertedCurrencyAmmount( total: any ) {
        this.bookingState.convertedCurrencyAmount = total;
    }


    @action.bound
    getConvertedCurrency( amount: any, from:any): Promise<boolean> {
        const data = {
            amount,
            from,
            to: "USD"
        };

        BookingAgent.misc.getConvertedCurrency(data)
        .then(resp => {
            //console.log(resp);
            runInAction(() => {
                if ( !!resp.data.data && !!resp.data.data.convertedAmount ) {
                    this.setConvertedCurrencyAmmount(resp.data.data.convertedAmount);
                }
            });
            return true
        })
        .catch( (error) => {
            // console.log("currency error", error);
            return false;
        })

        return
    }

    @action.bound
    getTotalWithTaxes( ): Promise<boolean> {
        
        const selectedOption = !!this.RootStore && !!this.RootStore.propertyModel 
            && !!this.RootStore.propertyModel.property && this.RootStore.propertyModel.property.isBookable[this.RootStore.propertyModel.selectedIndex];

        const searchFilters = !!this.RootStore && !!this.RootStore.searchModel 
            && this.RootStore.searchModel.filters;

        const data = {
            inventory_id: selectedOption.inventory_id,
            arrival_date: searchFilters.arrival_date,
            departure_date: searchFilters.departure_date,
            additional_fees: this.bookingState.additionalFees,
            inventory_rate_id: selectedOption.inventory_rate_id,
            additional_adults: this.bookingState.number_additional_adults,
            num_children: this.bookingState.number_additional_children,
            promo_code: searchFilters.promo_code,
            is_hotel_option: selectedOption.is_hotel_option,
            hotel_option_id: selectedOption.hotel_option_id,
        };

        BookingAgent.misc.getTotalWithTaxes(data)
        .then(resp => {
            //console.log(resp);
            runInAction(() => {
                if ( !!resp.data && !!resp.data ) {
                   this.bookingState.fee_total = resp.data.fee_total;
                   this.bookingState.tax_total = resp.data.tax_total;
                   this.bookingState.total = resp.data.total;
                   this.bookingState.total_day_rate = resp.data.total_day_rate;
                }
            });
            return true
        })
        .catch( (error) => {
            // console.log("currency error", error);
            return false;
        })

        return
    }

    /**
     * Submit Customize data
     */
    @action.bound
    bookNow(routerHistory: any, option: any, search_reference: any, promoCode:any): Promise<boolean> {
        
        
        //console.log("bookNow option", option );
        
        this.bookingState.selectedOption = option;


        //console.log("this.bookingState.selectedOption", this.bookingState.selectedOption );

        const fees: Array<string> = []; 
        this.bookingState.additionalFees.forEach((item) => {
            if (item.applied) {
                fees.push(item.name);
            }
        });

        const data = {
            selectedOption: this.bookingState.selectedOption,
            customizations: fees,
            search_reference,
            promoCode,
            email: this.bookingState.email
        }

        return BookingAgent.misc.booknow(data)
            .then(resp => {
                //console.log(resp);
                runInAction(() => {
                    this.bookingState.id = resp.data.data;
                    routerHistory.push(`${routes.path.guestInfo}/${this.bookingState.selectedOption.id}`);
                    window.scrollTo(0, 0);
                });
                return true
            })
            .catch( (error) => {
                console.log("submitCustomize error", error);
                return false;
            })
    }

    /**
     * Submit Guest Info data
     */
    @action.bound
    submitGuestInfo(routerHistory: any): Promise<void> | undefined {
        this.bookingState.requiredGuestInfoFields.forEach((field) => this.validateField(field));

        if (this.bookingState.validationErrors.length > 0) {
			//console.log("I am returning");
            return;
        }

        const guestInfoData: iGuestInfoData = {
            full_name: this.bookingState.full_name,
            mobile_number: this.bookingState.mobile_number,
            city: this.bookingState.city,
            state: this.bookingState.state,
            email: this.bookingState.email,
            address: this.bookingState.address,
            zip: this.bookingState.zip,
            country: this.bookingState.country,
            client_file_no: this.bookingState.client_file_no,
		}

        if (!!this.bookingState.title) { guestInfoData.title = this.bookingState.title }
        if (!!this.bookingState.address_2) { guestInfoData.address_2 = this.bookingState.address_2 }
		if (!!this.bookingState.company) { guestInfoData.company = this.bookingState.company }
		
		const fees: Array<string> = []; 
        this.bookingState.additionalFees.forEach((item) => {
            if (item.applied) {
                fees.push(item.name);
            }
        });

        const data = {
            bookingId: this.bookingState.id,
            selectedOption: this.bookingState.selectedOption,
            guestUser: guestInfoData,
            customizations: fees
        }

        //.log("this.bookingState.selectedOption", this.bookingState.selectedOption );

        if (this.bookingState.selectedOption.id) {
            return BookingAgent.misc.guestInfo(data)
                .then(resp => {
                    routerHistory.push(`${routes.path.rentalAgreement}/${data.selectedOption.id}`);
                    window.scrollTo(0, 0);
                })
                .catch((error) => {
                    console.log("submitGuestInfo error", error);
                })
        }
    }

    @action.bound
    setEzypayLogs(log: any) {
        // console.log("setEzypayLogs log", log);
        this.bookingState.ezpay_logs = log;
    }

    @action.bound
    clearEzypayLogs() {
        // console.log("clearEzypayLogs");
        this.bookingState.ezpay_logs = {};
    }

    @action.bound
    submitRentalAgreement(routerHistory: any): Promise<void> | undefined {
        this.bookingState.requiredRentalAgreementFields.forEach((fieldName) => { this.validateRentalAgreementValue(fieldName); });

        if (this.bookingState.validationErrors.length > 0) {
			// console.log("this.bookingState.validationErrors", this.bookingState.validationErrors);
            return;
        }

        const drawbacks = this.RootStore.propertyModel.property.isBookable[this.RootStore.propertyModel.selectedIndex]["drawbacks"];
        if (this.bookingState.validationErrors.length > 0) {
            if ( this.bookingState.validationErrors.includes("drawbacks") ) {
                if ( drawbacks !== null ) {
                    return;
                } 
            } else {
                return;
            }
        }
        
        // we may want to move this stuff out of this function
        /////////////////////////////////////////////////////
        if ( this.bookingState.cc_ref_id ) {
            //this.pusher.unsubscribe(this.bookingState.cc_ref_id);
            this.clearEzypayLogs()
        }
        
        const cc_ref_id = Math.random().toString(20).substring(2, 9).toUpperCase();
        this.setCCRefId(cc_ref_id);
        // this.channel = this.pusher.subscribe(this.bookingState.cc_ref_id);
        // this.channel.bind('payment-event', (data) => {
        //     this.setEzypayLogs(data.ezpay_logs);
        //     routerHistory.push(`${routes.path.bookingConfirmed}/${this.bookingState.selectedOption.id}`);
        // });

        // this.channel.bind('cancel-event', (data) => {
        //     //this.setEzypayLogs(data.eazypay_logs);
        //     //routerHistory.push(`${routes.path.bookingConfirmed}/${this.bookingState.selectedOption.id}`);
        //     // ("I have recieved the cancel event");
        //     // routerHistory.push(`${routes.path.rentalAgreement}/${data.selectedOption.id}`);
        //     window.scrollTo(0, 0);
        // });

        //cancel-event
        /////////////////////////////////////////////////////

        const rentalAgreementData: iRentalAgreementData = {
            vacate_policy: Number(!!this.bookingState.vacate_policy),
            cancellations: Number(!!this.bookingState.cancellations),
            renters_insurance: Number(!!this.bookingState.renters_insurance),
            signature: this.bookingState.signature,
            ip_address: this.bookingState.ip_address,
            drawback_agreement:  Number(!!this.bookingState.drawbacks),
            star_rating_agreement: Number(!!this.bookingState.star_rating),
            no_smoking_agreement: Number(!!this.bookingState.no_smoking)
        }

        const guestInfoData: iGuestInfoData = {
            full_name: this.bookingState.full_name,
            mobile_number: this.bookingState.mobile_number,
            city: this.bookingState.city,
            state: this.bookingState.state,
            email: this.bookingState.email,
            address: this.bookingState.address,
            zip: this.bookingState.zip,
            country: this.bookingState.country,
            client_file_no: this.bookingState.client_file_no
        }
        
        const fees: Array<string> = []; 
        this.bookingState.additionalFees.forEach((item) => {
            if (item.applied) {
                fees.push(item.name);
            }
        });

        const data = {
            bookingId: this.bookingState.id,
            selectedOption: this.bookingState.selectedOption,
            guestUser: guestInfoData,
            customizations: fees,
            rentalAgreementData,
            pusherPaymentChannelId: this.bookingState.cc_ref_id

        }

        //console.log("this.bookingState.selectedOption", this.bookingState.selectedOption );

        if (this.bookingState.id) {
            return BookingAgent.misc.rentalAgreement(data)
                .then(resp => {
                    if ( this.RootStore.searchModel.filters.is_client_billing ) {
                        routerHistory.push(`${routes.path.paymentDetails}/${this.bookingState.selectedOption.id}`);
                    } else {
                        routerHistory.push(`${routes.path.paymentDetails}/${this.bookingState.selectedOption.id}`);
                    }
                    
                    window.scrollTo(0, 0);
                })
                .catch((error) => {
                    console.log("submitRentalAgreement error", error);
                })
        }
    }

    @action.bound
    submitPaymentDatails(routerHistory: any ): Promise<void> | undefined {
        
        const rentalAgreementData: iRentalAgreementData = {
            vacate_policy: Number(!!this.bookingState.vacate_policy),
            cancellations: Number(!!this.bookingState.cancellations),
            renters_insurance: Number(!!this.bookingState.renters_insurance),
            signature: this.bookingState.signature,
            ip_address: this.bookingState.ip_address,
            drawback_agreement:  Number(!!this.bookingState.drawbacks),
            star_rating_agreement: Number(!!this.bookingState.star_rating),
            no_smoking_agreement: Number(!!this.bookingState.star_rating),
        }

        const guestInfoData: iGuestInfoData = {
            full_name: this.bookingState.full_name,
            mobile_number: this.bookingState.mobile_number,
            city: this.bookingState.city,
            state: this.bookingState.state,
            email: this.bookingState.email,
            address: this.bookingState.address,
            zip: this.bookingState.zip,
            country: this.bookingState.country,
            client_file_no: this.bookingState.client_file_no
        }
        
        const fees: Array<string> = []; 
        this.bookingState.additionalFees.forEach((item) => {
            if (item.applied) {
                fees.push(item.name);
            }
        });

        const data = {
            bookingId: this.bookingState.id,
            selectedOption: this.bookingState.selectedOption,
            guestUser: guestInfoData,
            customizations: fees,
            rentalAgreementData,
            paymentConfirmationNumber: 0,
            //decryptedData
        }

        if (this.bookingState.selectedOption.id) {
            return BookingAgent.misc.submitPaymentDatails(data)
                .then(resp => {
                    //console.log("resp", resp);
                    routerHistory.push(`${routes.path.bookingConfirmed}/${data.selectedOption.id}`);
                    window.scrollTo(0, 0);
                })
                .catch((error) => {
                    console.log("submitPaymentDatails error", error);
                    //just for now 
                    routerHistory.push(`${routes.path.bookingConfirmed}/${data.selectedOption.id}`);
                    window.scrollTo(0, 0);
                })
        }
        return
    }

    @action.bound
    updateValue(valueName: string, value: any) {
        //console.log('valueName', valueName, value)
        if (valueName in this.bookingState) {
            (this.bookingState as any)[valueName] = value;
            this.validateField(valueName);
        }
    }
    
    @action.bound
    updateSearchValue(valueName: string, value: any) {
        if (valueName in this.bookingState) {
            (this.bookingState as any)[valueName] = value;
            //this.validateSearchField(valueName);
        }
	}
	
	@action.bound
    updateBillingAsGuest(isSame:boolean) {
		if ( isSame ) {
			this.bookingState.cc_billing_address = this.bookingState.address;
			this.bookingState.cc_address_2 = this.bookingState.address_2;
			this.bookingState.cc_city = this.bookingState.city;
			this.bookingState.cc_state = this.bookingState.state;
			this.bookingState.cc_zip = this.bookingState.zip;
			this.bookingState.cc_country = this.bookingState.country;
		} else {
			this.bookingState.cc_billing_address = "";
			this.bookingState.cc_address_2 = "";
			this.bookingState.cc_city = "";
			this.bookingState.cc_state = "";
			this.bookingState.cc_zip = "";
			this.bookingState.cc_country = "";
		}
       
    }

    @action.bound
    validateField(fieldName: string) {
        const emailRule = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const phoneRule = /^(?:(?:(\s*\(?([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\)?\s*(?:[.-]\s*)?)([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})/;
        if (fieldName === "email_confirmation" || fieldName === "email") {
            if (this.bookingState.email !== this.bookingState.email_confirmation) {
                if (!this.bookingState.validationErrors.includes("email_confirmation")) {
                    this.bookingState.validationErrors.push("email_confirmation");
                }
            } else {
                if (this.bookingState.validationErrors.includes("email_confirmation")) {
                    const index = this.bookingState.validationErrors.findIndex((item) => item === "email_confirmation");
                    this.bookingState.validationErrors.splice(index, 1);
                }
            }
        }

        const fieldValue = (this.bookingState as any)[fieldName];

        if (fieldName === "email") {
            if (!emailRule.test(fieldValue.toString().toLowerCase())) {
                if (!this.bookingState.validationErrors.includes("email")) {
                    this.bookingState.validationErrors.push("email");
                }
            } else {
                const index = this.bookingState.validationErrors.findIndex((item) => item === "email");
                if (index > -1) {
                    this.bookingState.validationErrors.splice(index, 1);
                }
            }
        } else if ( fieldName === "mobile_number" ) {
            if ( !phoneRule.test(fieldValue.toString().toLowerCase()) ) {
                if (!this.bookingState.validationErrors.includes("mobile_number")) {
                    this.bookingState.validationErrors.push("mobile_number");
                }
            } else {
                const index = this.bookingState.validationErrors.findIndex((item) => item === "mobile_number");
                if (index > -1) {
                    this.bookingState.validationErrors.splice(index, 1);
                }
            }
        } else if ( fieldName === "rental_signature_full_name" ) {
            if (this.bookingState.requiredRentalAgreementFields.includes(fieldName))
            {
                if (!fieldValue) {
                    if (!this.bookingState.validationErrors.includes(fieldName)) {
                        this.bookingState.validationErrors.push(fieldName);
                    }
                } else {
                    const index = this.bookingState.validationErrors.findIndex((item) => item === fieldName);
                    if (index > -1) {
                        this.bookingState.validationErrors.splice(index, 1);
                    }
                }
            }
        }else {
            if (this.bookingState.requiredGuestInfoFields.includes(fieldName)
                || this.bookingState.requiredBillingInfoFields.includes(fieldName))
            {
                if (!fieldValue) {
                    if (!this.bookingState.validationErrors.includes(fieldName)) {
                        this.bookingState.validationErrors.push(fieldName);
                    }
                } else {
                    const index = this.bookingState.validationErrors.findIndex((item) => item === fieldName);
                    if (index > -1) {
                        this.bookingState.validationErrors.splice(index, 1);
                    }
                }
            }
        }
    }

    @action.bound
    validateSearchField(fieldName: string): boolean {
        let retVal = false;
        const emailRule = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const fieldValue = (this.bookingState as any)[fieldName];

        //console.log("validateSearchField fieldName", fieldName);

        //console.log("validateSearchField fieldValue", fieldValue);

        if (fieldName === "email") {
            if (!emailRule.test(fieldValue.toString().toLowerCase())) {
                if (!this.bookingState.searchValidationErrors.includes("email")) {
                    this.bookingState.searchValidationErrors.push("email");
                }
            } else {
                const index = this.bookingState.searchValidationErrors.findIndex((item) => item === "email");
                if (index > -1) {
                    this.bookingState.searchValidationErrors.splice(index, 1);
                }
                retVal = true;
            }
        } else {
            if (this.bookingState.requiredSearchInfoFields.includes(fieldName))
            {
                if (!fieldValue) {
                    if (!this.bookingState.searchValidationErrors.includes(fieldName)) {
                        this.bookingState.searchValidationErrors.push(fieldName);
                    }
                } else {
                    const index = this.bookingState.searchValidationErrors.findIndex((item) => item === fieldName);
                    if (index > -1) {
                        this.bookingState.searchValidationErrors.splice(index, 1);
                    }
                    retVal = true;
                }
            }
        }
        return retVal;
    }

    /**
     * Update and validate field from 'Rental Agreement' step by name
     * @param {string} valueName
     * @param {any} value
     */
    @action.bound
    updateRentalAgreementValue(valueName: string, value: any) {  
        if (valueName in this.bookingState) {
            (this.bookingState as any)[valueName] = value;
            this.validateRentalAgreementValue(valueName);
        }
    }

    /**
     * Validate field from 'Rental Agreement' step by name
     * @param {string} fieldName
     */
    @action.bound
    validateRentalAgreementValue(fieldName: string) {
        const fieldValue = !!(this.bookingState as any)[fieldName];

        if (!fieldValue) {
            if (!this.bookingState.validationErrors.includes(fieldName)) {
                this.bookingState.validationErrors.push(fieldName);
            }
        } else {
            const index = this.bookingState.validationErrors.findIndex((item) => item === fieldName);
            if (index > -1) {
                this.bookingState.validationErrors.splice(index, 1);
            }
        }
    }

    /**
     * FIXME: Remove when not needed anymore
     * Update payment details value when input changes
     */
    @action.bound
    updatePaymentDetailsValue(valueName: string, value: any) {
        if (valueName in this.paymentDetails) {
            (this.paymentDetails as any)[valueName] = value;
            this.validatePaymentDetailsField(valueName);
        }
    }

    @action.bound
    addDrawbacksToValidation(valueName: string) {
        this.bookingState.requiredRentalAgreementFields.push(valueName);
    }

    /**
     * FIXME: Remove when not needed anymore
     * Validate payment details value
     */
    @action.bound
    validatePaymentDetailsField(fieldName: string) {
        if (!(this.paymentDetails as any)[fieldName]) {
            if (!this.paymentDetails.validationErrors.includes(fieldName)) {
                this.paymentDetails.validationErrors.push(fieldName);
            }
        } else {
            if (this.paymentDetails.validationErrors.includes(fieldName)) {
                const index = this.paymentDetails.validationErrors.findIndex((item) => item === fieldName);
                this.paymentDetails.validationErrors.splice(index, 1);
            }
        }
    }
}
