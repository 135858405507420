import SearchRoutes from './Search/routes';
const routes = Object.freeze({
  path: {
    instantSearch: '/properties',
    propertySingle: '/marketing/'
  },
  ajax: {
    getOptions: 'properties/options',
    instantSearch: '/self-serve/properties/general-inventory',
    propertySingle: '/self-serve/show-property/',
	  propertyAvailabilities: '/self-serve/show-property/available-inventory',
	  getFeesAndTaxes: "/self-serve/show-property/custom_fees_and_taxes"
  }
})

export default routes;