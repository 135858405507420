import {
  iWalkScoreParams,
  iGetNeighborsParams_1,
  iGetNeighborsParams_2
} from "./interfaces";

import nomadXHR from '../adapter';
import routes from './routes';
import {iQueryParams} from "./Search/interfaces";

export const Properties = {
  getAll: ()=>{

  },
  get: ( id: number, start_date: any, end_date:any, promoCode:any, search_reference_id:any) => nomadXHR({
    method: 'get',
    url: routes.ajax.propertySingle + id,
    params: {
      start_date,
      end_date,
      promoCode,
      search_reference_id
    }
  }),
  getHotel: ( id: number, start_date: any, end_date:any, promoCode:any, search_reference_id:any, hotel_option_id:any, is_hotel_option:any=1) => nomadXHR({
    method: 'get',
    url: routes.ajax.propertySingle + id,
    params: {
      start_date,
      end_date,
      promoCode,
      search_reference_id,
      is_hotel_option,
      hotel_option_id
    }
  }),
  getOptions: (params: {lead_id: number, property_id: number}) => nomadXHR({
    method: 'get',
    url: routes.ajax.getOptions,
    params: params,
  }),
  getWalkScore: (params: iWalkScoreParams) => nomadXHR({
    method: 'get',
    url: "/properties/" + 'walkscore',
    params: params,
  }),
  getPropertyAvailabilities: (params: {property_id: number, start_date: any, end_date: any, promoCode: any}) => nomadXHR({
    method: 'get',
    url: routes.ajax.propertyAvailabilities,
    params: params,
  }),
  getNeighborhoods: (params: iGetNeighborsParams_1 | iGetNeighborsParams_2) => nomadXHR({
    method: 'get',
    url: routes.ajax.propertySingle + 'recommended_neighborhoods',
    params: params,
  }),
};
