import { observable, action, computed, runInAction } from "mobx";
import moment from "moment";
import { iSearchFilters, iQueryParams, iAmenities, iSearchState } from './interfaces';
import { Properties } from "./agent";
import { ChangeEvent } from "react";
import { RootStore } from "../../RootStore";
import Pusher from 'pusher-js';

import { persist } from 'mobx-persist'
class Search {

    public searchReference: string;
    public previousPage: number;
    public sameSearch: boolean;
    public previousParamsObj: any;

    protected RootStore: RootStore;
    protected pusher: any;
    protected channel: any;
    protected history: any;
    protected emailResults: boolean;
    

    constructor(RootStore: RootStore) {
        this.RootStore = RootStore;
        this.previousPage = 1;
        this.sameSearch = false;
        this.previousParamsObj = null;
        // this.pusher = new Pusher("e6099aa4f9fe130e22dc", {
        //     cluster: 'us3',
        // });

        this.history = null;
        Properties.misc.getAmenities()
            .then(res => {
                runInAction(() => {
                    this.filters.amenities = res.data.filter((item) => item.do_not_display !== 1);

                    // console.log("Properties.misc.getAmenities runInAction this.filters.amenities", this.filters.amenities);

                })
            })
            .catch(e => {
                console.log(e);
            });
    }

    protected ratingArray = Properties.misc.getRatingOptions();

    @persist('object') @observable
    searchState: iSearchState = {
        email: "",
        full_name: "",
        promo_code: "",
        // FIXME: should be received from Search store
        // option_id: 3870,
        searchValidationErrors: ["full_name", "email", "arrival_date", "departure_date", "apartment_type"],
        requiredSearchInfoFields: ["full_name", "email", "promo_code", "arrival_date", "departure_date", "apartment_type"]
    }

    @persist('object') @observable
    filters: iSearchFilters = {
        address: '',
        apartmentTypes: Properties.misc.getApartmentTypes(),
        lat: 0,
        long: 0,
        placeID: '',
        rate: Properties.misc.getRateRanges(),
        range: 25,
        amenities: [],
        rating: this.ratingArray,
        sorting: Properties.misc.getSortingOptions(),
        numberOfGuests: Properties.misc.getNumberOfGuests(),
        arrival_date: undefined,
        departure_date: undefined,
        promoCode: '',
        page: 1,
        perPage: 24,
        total: 0,
        sortType: 0,
        country_name: '',
        city_name: '',
        state_name: '',
        search_reference: '',
        include_oscar: 0,
        offset: 0,
        tempRange: 25,
        tempRating: this.ratingArray,
        email: "",
        full_name: "",
        promo_code: "",
        // FIXME: should be received from Search store
        // option_id: 3870,
        searchValidationErrors: ["full_name", "email", "arrival_date", "departure_date", "apartment_type"],
        requiredSearchInfoFields: ["full_name", "email", "promo_code", "arrival_date", "departure_date", "apartment_type"],
        apartment_type: [],
        is_client_billing: false,
        hide_rates: false,
        restricted_bedroom_type: [],
        max_days: undefined
    }

    @observable
    queryParamsObj: iQueryParams = {
        long: 0,
        lat: 0,
        limit: 24,
        range: 25
    }

    @persist('object') @observable
    ui = {
        isPromoCodeModalVisible: false,
        showAdditionalResultsModal: false,
        showAdditionalResultsWarning: false,
        showAdditionalResultsMessage: false,
        hasOscarResults: false,
        showStarRatingModal: false,
        showOscar: false,
        validPromoCode: false,
        usePromoCode: false,
        hasRatingBeenApplied: false,
        promo_code_error_message: ""
    }

    @persist('object') @observable
    timestamp = {
        lastSearch: undefined
    }


    @action.bound
    updateQueryParameters(params: any) {
        //console.log("updateQueryParameters", params);
    }

    /**
     * set current location
     */

    @action.bound
    setCurrentLocation() {
        window.navigator.geolocation.getCurrentPosition((res) => {
            runInAction(() => {
                this.filters.lat = res.coords.latitude;
                this.filters.long = res.coords.longitude;
            });
            this.updateQueryParams();
        }, err => { console.log(err) }, { timeout: 0 })
    }

    /**
     * Updates address string
     * @param {string} newAddress
     */
    @action.bound
    updateAddress(e: ChangeEvent<HTMLInputElement> | string, geocode?: any) {
        const sanitizedAddress = (typeof e === 'string') ? e : e.target.value;
        this.filters.address = sanitizedAddress;
        if (geocode && geocode.lat && geocode.lng ) {
            this.filters.lat = geocode.lat;
            this.filters.long = geocode.lng;
            this.filters.country_name = geocode.country || "";
            this.filters.city_name = geocode.city || "";
            this.filters.state_name = geocode.state || "";
            this.queryParamsObj = {...this.queryParamsObj}
            this.queryParamsObj['lat'] = this.filters.lat;
            this.queryParamsObj['long'] = this.filters.long;
            this.updateQueryParams();
        }
    }

    @action.bound
    updatePromoAddress(address: any) {
        this.filters.address = address;
        this.queryParamsObj = {...this.queryParamsObj}
        this.queryParamsObj['lat'] = this.filters.lat;
        this.queryParamsObj['long'] = this.filters.long;
        this.updateQueryParams();
    }

    /**
     * Updates apartment type
     * @param {number} id apartment type id
     * @param {boolean} selected is current type selected
     */
    @action.bound
    updateApartmentTypes(id: number, selected: boolean) {
        if (!Array.isArray(this.filters.apartmentTypes)) return;
        for (let i = this.filters.apartmentTypes.length; i--;) {
            if (this.filters.apartmentTypes[i].id === id) {
                this.filters.apartmentTypes[i].selected = selected;
            }
        }

        let apartment_types = this.filters.apartmentTypes.map(type => {
            if (type.selected) return type.name
        }).filter(name => !!name);
        apartment_types.length && (this.queryParamsObj.apartment_type = apartment_types);
        //with the filters for the validation of promo code box making life easy
        apartment_types.length && (this.filters.apartment_type = apartment_types);
        this.validateSearchField("apartment_type");
    }

    @action.bound
    applyApartmentTypes() {

        // we are using this below to set the validator and the params object
        let apartment_types = this.filters.apartmentTypes.map(type => {
            if (type.selected) return type.name
        }).filter(name => !!name);
        apartment_types.length && (this.queryParamsObj.apartment_type = apartment_types);
        //with the filters for the validation of promo code box making life easy
        apartment_types.length && (this.filters.apartment_type = apartment_types);

        if ( apartment_types.length > 0 ) {
            this.filters.page = 1;
            this.filters.offset = 0;
            this.updateQueryParams();

            // here is where we want to decide if we are using query parameters
            // or if we are using the state machine.
            if ( this.filters.promo_code ) {
                this.searchWithCodeAndFilters(true);
            } else {
                this.searchWithQueryParams(this.filters, this.history);
            }
        }
    }

    @action.bound
    setValidPromoCode(bool: any) {
        this.ui.validPromoCode = bool;
    }

    @action.bound
    clearAddress() {
        this.filters.address = "";
    }

    /**
     * Updates amenities
     * @param {number} id amenity id
     * @param {boolean} selected is current amenity selected
     */
    @action.bound
    updateAmenities(id: number, selected: boolean) {
        if (!Array.isArray(this.filters.amenities)) return;
        for (let i = this.filters.amenities.length; i--;) {
            if (this.filters.amenities[i].id === id) {
                this.filters.amenities[i].selected = selected;
                return;
            }
        }
    }

    @action.bound
    applyAmenities() {
        this.filters.page = 1;
        this.filters.offset = 0;
        this.updateQueryParams();
        // here is where we want to decide if we are using query parameters
        // or if we are using the state machine.
        if ( this.filters.promo_code ) {
            this.searchWithCodeAndFilters(true);
        } else {
            this.searchWithQueryParams(this.filters, this.history);
        }
    }

    /**
     * Updates move in, move out dates and minEndDate
     * @param {iStayDates} newDates
     * @returns void
     */
    @action.bound
    updateDates(newDates: { start: string, end: string }) {
        this.filters.arrival_date = newDates.start;
        this.filters.departure_date = newDates.end;

        this.RootStore.bookingModel.setDatesError(false);
        // console.log("this.RootStore.bookingMode1.bookingState.show_dates_error", this.RootStore.bookingModel.bookingState.show_dates_error);
        
        const today = moment();
        const diffStartDate = moment(newDates.start).diff(today, 'days');
        const diffLastStartDate = moment(newDates.start).diff(today, 'days');
        
        
        if ( diffStartDate.toString() < "15" ) {
            // console.log("this.RootStore.bookingMode1.bookingState.show_dates_error", this.RootStore.bookingModel.bookingState.show_dates_error);
            // this.RootStore.bookingModel.setDatesError(true);
        }

        if ( diffLastStartDate.toString() > "34" ) {
            // console.log("this.RootStore.bookingMode1.bookingState.show_dates_error", this.RootStore.bookingModel.bookingState.show_dates_error);
            // this.RootStore.bookingModel.setDatesError(true);
        }

        // console.log("this.RootStore.bookingMode1.bookingState.show_dates_error", this.RootStore.bookingModel.bookingState.show_dates_error);

        this.validateSearchField("arrival_date");
        this.validateSearchField("departure_date");
    }

    /**
     * Applies move in, move out dates and minEndDate
     * @returns void
     */
    @action.bound
    applyNewDates() {
        this.filters.page = 1;
        this.filters.offset = 0;
        this.updateQueryParams();

        // here is where we want to decide if we are using query parameters
        // or if we are using the state machine.
        if ( this.filters.promo_code ) {
            this.searchWithCodeAndFilters(true);
        } else {
            this.searchWithQueryParams(this.filters, this.history);
        }
    }

    /**
     * Updates rate range values
     * @param newData
     * @returns void
     */
    @action.bound
    updateRateRange(newValue: { min: number, max: number } | Range | number) {
        typeof newValue === 'object' && !(newValue instanceof Range) &&
            newValue.min && newValue.max && (this.filters.rate.value = { min: newValue.min, max: newValue.max });
        //this.updateQueryParams();
    }

    /**
     * Applies rate range values
     * @returns void
     */
    @action.bound
    applyNewRateRange () {
        this.filters.page = 1;
        this.filters.offset = 0;
        this.updateQueryParams();

        // here is where we want to decide if we are using query parameters
        // or if we are using the state machine.
        if ( this.filters.promo_code ) {
            this.searchWithCodeAndFilters(true);
        } else {
            this.searchWithQueryParams(this.filters, this.history);
        }
    }

    /**
     * Updates rating data
     * @param {number} id
     * @param {boolean} selected
     */
    @action.bound
    updateRating(id: number, selected: boolean) {

        if (!Array.isArray(this.filters.rating)) return;
        for (let i = this.filters.rating.length; i--;) {
            if (this.filters.rating[i].id === id) {
                this.filters.rating[i].selected = selected;
            }
        }

        console.log("this.filters.rating", this.filters.rating );
    }

    @action.bound
    applyNewRating() {

        console.log("applyNewRating");
        //this.ui.hasRatingBeenApplied = true;
        this.filters.page = 1;
        this.filters.offset = 0;
        // here is where we want to decide if we are using query parameters
        // or if we are using the state machine.
        if ( this.filters.promo_code ) {
            this.searchWithCodeAndFilters(true);
        } else {

            console.log(" we do not have a promo code");
            this.searchWithQueryParams(this.filters, this.history);
        }
    }

    /**
     * Updates distance range data
     * @param {number} value
     */
    @action.bound
    updateTempRange(value: number) {
        this.filters.tempRange = value;
    }

    @action.bound
    applyNewRange() {
        if (this.filters.tempRange !== this.filters.range) {
            this.filters.range = this.filters.tempRange;
        }
        this.filters.page = 1;
        this.filters.offset = 0;
        this.updateQueryParams();

        // here is where we want to decide if we are using query parameters
        // or if we are using the state machine.
        if ( this.filters.promo_code ) {
            this.searchWithCodeAndFilters(true);
        } else {
            this.searchWithQueryParams(this.filters, this.history);
        }
    }

    @action.bound
    updateSearchRef() {
        this.clearSearchRef();
        this.timestamp.lastSearch = moment();
        this.includeOscar(0);
        //this.pusher.unsubscribe(this.searchReference);
        const message = Math.random().toString(36).substring(3);
        //console.log("updateSearchRef", message);
        this.searchReference = message;
        this.filters.search_reference = this.searchReference;
        this.ui.showOscar = false;
        this.updateQueryParams();
       // this.channel = this.pusher.subscribe(this.filters.search_reference);
        // after we subscribe we want to tell them about the extra search.
        this.emailResults = false;
        this.setOscarResults(false);
        this.toggleAdditionalResultsMessage();
        // pop open model
        this.toggleAdditionalResultsWarning();
        // this.channel.bind('oscar-event', (data) => {
        //     //console.log("oscar-event");
        //     if (this.emailResults) return;
        //     if (data.has_results === 1) {
        //         this.setOscarResults(true);
        //         // if we have data and the warning is open close the warning
        //         if (this.ui.showAdditionalResultsWarning) {
        //             this.toggleAdditionalResultsWarning();
        //         }
        //         // open more results choice modal
        //         this.toggleAdditionalResultsVisibility();
        //     } else {
        //         if (this.ui.showAdditionalResultsWarning) {
        //             this.toggleAdditionalResultsWarning();
        //         }
        //         this.setOscarResults(false);
        //         this.toggleAdditionalResultsVisibility();
        //     }
        // });

    }

    @action.bound
    includeOscar(value) {
        this.filters.include_oscar = value;
        this.updateQueryParams();
        // console.log("includeOscar this.filters.include_oscar", this.filters.include_oscar);
        //alert(JSON.stringify("We will search for oscar now"));
        if (value === 1) {
            this.applyOscarFilters(this.history);
            this.toggleAdditionalResultsVisibility();
        }
    }

    @action.bound
    toggleAdditionalResultsVisibility() {
        this.ui.showAdditionalResultsModal = !this.ui.showAdditionalResultsModal;
        // console.log("toggleAdditionalResultsVisibility() this.ui.showAdditionalResultsModal", this.ui.showAdditionalResultsModal );
    }

    @action.bound
    setOscarResults(bool) {
        this.ui.hasOscarResults = bool;
        // console.log("toggleAdditionalResultsVisibility() this.ui.showAdditionalResultsModal", this.ui.showAdditionalResultsModal );
    }

    @action.bound
    toggleAdditionalResultsWarning() {
        this.ui.showAdditionalResultsWarning = !this.ui.showAdditionalResultsWarning;
        // console.log("toggleAdditionalResultsVisibility() this.ui.showAdditionalResultsWarning", this.ui.showAdditionalResultsWarning );
    }

    @action.bound
    toggleAdditionalResultsMessage() {
        this.ui.showAdditionalResultsMessage = !this.ui.showAdditionalResultsMessage;
    }

    @action.bound
    setAdditionalResultsWarning(value) {
        this.ui.showAdditionalResultsWarning = value;
        // console.log("toggleAdditionalResultsVisibility() this.ui.showAdditionalResultsWarning", this.ui.showAdditionalResultsWarning );
    }

    @action.bound
    requestEmailResults() {
        // console.log("requestEmailResults");
        this.toggleAdditionalResultsWarning();
        this.emailResults = true;
        Properties.emailRequest(this.filters.search_reference);
    }

    @action.bound
    clearSearchRef() {
        this.includeOscar(0)
        this.ui.usePromoCode = false;
        this.searchReference = null;
        // console.log("clearSearchRef this.searchReference", this.searchReference);
    }

    /**
     * Updates request data in queryPrams object
     * @returns void
     */
    @action.bound
    updateQueryParams() {
        if (!!this.queryParamsObj) {
            this.previousParamsObj = this.queryParamsObj
        }

        if (!this.filters.promoCode) {
            this.queryParamsObj = {
                long: this.filters.long,
                lat: this.filters.lat,
                limit: this.filters.perPage,
                range: this.filters.range,
                page: this.filters.page,
                offset: this.filters.offset
            };

            if (Array.isArray(this.filters.sorting) && this.filters.sorting.length) {
                for (let i = this.filters.sorting.length; i--;) {

                    if (!this.filters.sorting[i].selected) continue;

                    this.queryParamsObj.sort_order = this.filters.sorting[i].dir;
                    this.queryParamsObj.sort_by = this.filters.sorting[i].type;

                    break;
                }
            }

            if (Array.isArray(this.filters.amenities) && this.filters.amenities.length) {
            let amenities = this.filters.amenities.map(am => {
                if (am.selected) return am.id
            }).filter(am => !!am);
            amenities.length && (this.queryParamsObj.amenities = amenities);
            }

            if (Array.isArray(this.filters.rating) && this.filters.rating.length) {
                let admin_rating = this.filters.rating.map(am => {
                    if (am.selected) return am.value
                }).filter(am => !!am);
                admin_rating.length && (this.queryParamsObj.admin_rating = admin_rating);
            }

            if (this.filters.page) {
                this.queryParamsObj.page = this.filters.page;
            }

            if (this.filters.offset) {
                this.queryParamsObj.offset = this.filters.offset;
            }
        }


        if (this.filters.rate && this.filters.rate.hasOwnProperty('value')) {
          this.queryParamsObj.min_rate = this.filters.rate.value.min;
          this.queryParamsObj.max_rate = this.filters.rate.value.max;
        }
    }
    @action.bound
    searchWithCodeAndFilters( isDirty: boolean = null) {
        //console.log("Here I will search with my Code and my filters");
        //when we search with the code and filter we have a the concept of timed searched
        //here we will get the time

        this.timestamp.lastSearch = moment();

        //Next with a code search we want to have oscar properties included 
        // 1 for true / 0 for false
        this.filters.include_oscar = 1;
        // during this inital search phase we set the oscar UI to flase
        this.ui.showOscar = false;

        //Next we need generate a new search referance token
        // if we do not already have one.
         // here we will generate a search web token
        
        if ( !this.filters.search_reference ) {
            const searchRefToken = Math.random().toString(36).substring(3);
            //console.log("searchRefToken", searchRefToken);
            this.filters.search_reference = searchRefToken;
        }
        // we will check if its dirty form use of filters
        if ( !!isDirty ) {
            //console.log("The filters have changed and I need a new search Token, webref, and to clear the property id of last searched");
            // i will clear the cached property  id 
            this.RootStore.propertyModel.clearPropertyId();
            const searchRefToken = Math.random().toString(36).substring(3);
            //console.log("searchRefToken", searchRefToken);
            this.filters.search_reference = searchRefToken;
        }

        // here we will generate a search web token
        //now that we have a search token we will use that token to subscribe to pusher
       //  this.channel = this.pusher.subscribe(this.filters.search_reference);

        //now we set same basic default ui for the property search page
        this.emailResults = false;
        this.setOscarResults(false);
        // we want to let the user know that we could have extra results after the oscar search
        // so here we toggle that setting on 
        // we turn the message on TODO: refactor this
        this.toggleAdditionalResultsMessage();
        //then we pop up the warning panel at top of page
        this.toggleAdditionalResultsWarning();

        // now we will listen for the oscar results.
        // this.channel.bind('oscar-event', (data) => {
        //     //console.log("oscar-event");
        //     if (this.emailResults) return;
        //     if (data.has_results === 1) {
        //         this.setOscarResults(true);
        //         // if we have data and the warning is open close the warning
        //         if (this.ui.showAdditionalResultsWarning) {
        //             this.toggleAdditionalResultsWarning();
        //         }
        //         // open more results choice modal
        //         this.toggleAdditionalResultsVisibility();
        //     } else {
        //         if (this.ui.showAdditionalResultsWarning) {
        //             this.toggleAdditionalResultsWarning();
        //         }
        //         this.setOscarResults(false);
        //         this.toggleAdditionalResultsVisibility();
        //     }
        // });
        
        
        // console.log("this.searchModel.filters.address", this.filters.address);
        // console.log("this.searchModel.filters.arrival_date", this.filters.arrival_date);
        // console.log("this.searchModel.filters.departure_date", this.filters.departure_date);
        // console.log("this.searchModel.filters.include_oscar", this.filters.include_oscar);
        // console.log("this.searchModel.filters.lat", this.filters.lat);
        // console.log("this.searchModel.filters.long", this.filters.long);
        // console.log("this.searchModel.filters.page", this.filters.page);
        // console.log("this.searchModel.filters.offset", this.filters.offset);
        // console.log("this.searchModel.filters.promoCode", this.filters.promoCode);
        // console.log("this.searchModel.filters.range", this.filters.range);
        // console.log("this.searchModel.filters.rate", this.filters.rate);
        // console.log("this.searchModel.filters.rating", this.filters.rating);
        // console.log("this.searchModel.filters.search_reference", this.filters.search_reference);
        // console.log("this.searchModel.filters.sortType", this.filters.sortType);
        // console.log("this.searchModel.filters.state_name", this.filters.state_name);
        // console.log("this.searchModel.filters.total", this.filters.total);
        // console.log("this.searchModel.filters.apartmentTypes", this.filters.apartmentTypes);
        // console.log("I should try and build parameters from my filters only");

        if ( !!this.filters && this.filters.lat ) {
            this.queryParamsObj.long = this.filters.long;
        }
        if ( !!this.filters && this.filters.lat ) {
            this.queryParamsObj.lat = this.filters.lat;
        }
        if ( !!this.filters && this.filters.arrival_date ) {
            this.queryParamsObj.arrival_date = moment(this.filters.arrival_date).format("Y-MM-DD");
        }
        if ( !!this.filters && this.filters.departure_date ) {
            this.queryParamsObj.departure_date = moment(this.filters.departure_date).format("Y-MM-DD");
        }
        if ( !!this.filters && this.filters.include_oscar ) {
            this.queryParamsObj.include_oscar = this.filters.include_oscar;
        }
        if ( !!this.filters && this.filters.page ) {
            this.queryParamsObj.page = this.filters.page;
        }
        if ( !!this.filters && this.filters.offset ) {
            this.queryParamsObj.offset = this.filters.offset;
        }
        if ( !!this.filters && this.filters.promoCode ) {
            this.queryParamsObj.promoCode = this.filters.promoCode;
        }
        if ( !!this.filters && this.filters.range ) {
            this.queryParamsObj.range = this.filters.range;
        }
        if ( !!this.filters && this.filters.rate ) {
            this.queryParamsObj.rateRange = this.filters.rate;
        }
        if ( !!this.filters && this.filters.rating ) {
            this.queryParamsObj.rating = this.filters.rating;
        }
        if ( !!this.filters && this.filters.search_reference ) {
            this.queryParamsObj.search_reference = this.filters.search_reference;
        }
        if ( !!this.filters && this.filters.state_name ) {
            this.queryParamsObj.state_name = this.filters.state_name;
        }
        if ( !!this.filters && this.filters.country_name ) {
            this.queryParamsObj.country_name = this.filters.country_name;
        }
        if ( !!this.filters && this.filters.city_name ) {
            this.queryParamsObj.city_name = this.filters.city_name;
        }
        if ( !!this.filters && this.filters.total ) {
            this.queryParamsObj.total = this.filters.total;
        }
        if (Array.isArray(this.filters.apartmentTypes) && this.filters.apartmentTypes.length) {
          let apartment_types = this.filters.apartmentTypes.map(type => {
            if (type.selected) return type.name
          }).filter(name => !!name);
          apartment_types.length && (this.queryParamsObj.apartment_type = apartment_types);
          //with the filters for the validation making life easy
          apartment_types.length && (this.filters.apartment_type = apartment_types);

          // if for some reason apartment type is empty we will look for all apartment types
          if ( apartment_types.length === 0 ) {
            let apartment_types = this.filters.apartmentTypes.map(type => {
                return type.name
              }).filter(name => !!name);
              apartment_types.length && (this.queryParamsObj.apartment_type = apartment_types);

            //with the filters for the validation making life easy
            apartment_types.length && (this.filters.apartment_type = apartment_types);
          }
        }

        if (Array.isArray(this.filters.amenities) && this.filters.amenities.length) {
        let amenities = this.filters.amenities.map(am => {
            if (am.selected) return am.id
        }).filter(am => !!am);
        amenities.length && (this.queryParamsObj.amenities = amenities);
        }

        if (Array.isArray(this.filters.rating) && this.filters.rating.length) {
          let admin_rating = this.filters.rating.map(am => {
            if (am.selected) return am.value
          }).filter(am => !!am);
          admin_rating.length && (this.queryParamsObj.admin_rating = admin_rating);
        }

        if (this.filters.rate && this.filters.rate.hasOwnProperty('value')) {
            this.queryParamsObj.min_rate = this.filters.rate.value.min;
            this.queryParamsObj.max_rate = this.filters.rate.value.max;
        }

        if (Array.isArray(this.filters.sorting) && this.filters.sorting.length) {
            for (let i = this.filters.sorting.length; i--;) {

                if (!this.filters.sorting[i].selected) continue;

                this.queryParamsObj.sort_order = this.filters.sorting[i].dir;
                this.queryParamsObj.sort_by = this.filters.sorting[i].type;

                break;
            }
        }

        //console.log("this.queryParamsObj", this.queryParamsObj);
        return this.RootStore.propListModel.updatePropertyList(this.queryParamsObj)
            .then(result => {
                result.hasOwnProperty('offset') && runInAction(() => {
                });

                result.hasOwnProperty('items') && runInAction(() => {
                    this.RootStore.propListModel.propertyList = this.RootStore.propListModel.propertyList;
                });
            }
        );
    }

    @action.bound
    setApartmentTypeCheckboxesEnabled(bool: boolean) {
        // we want to diabled the checkboxes for apartment types if they are searching 
        for (let i = this.filters.apartmentTypes.length; i--;) {
            this.filters.apartmentTypes[i].disabled = bool;
        }
    }

    /**
     * Updates search
     * @param {string} urlParamsString
     */
    @action.bound
    searchWithQueryParams(params: any, routerHistory) {
        //const urlParams = new URLSearchParams(urlParamsString);
        // const keys = urlParams.keys();

        if ( !this.history ) {
            this.history = routerHistory;
        }

        // if we dont have a promo code we do not want all the filters in the url.
        if (!this.ui.validPromoCode) {
            //console.log("I do not have a promocode so I will only apply certain filters from url");


            for (const key in params) {
                
                // console.log("key", key );
                
                switch (key) {
                    case 'long':
                        //console.log("long", params[key]);
                        this.filters.long = parseFloat(params[key] as string);
                        this.queryParamsObj.long = params[key];
                        break;

                    case 'lat':
                        //console.log("lat", params[key]);
                        this.filters.lat = parseFloat(params[key] as string);
                        this.queryParamsObj.lat = params[key];
                        break;

                    case 'page':
                        //console.log("range", params[key]);   
                        this.filters.page = parseInt(params[key]);
                        this.queryParamsObj.page = params[key];
                        break;

                    case 'offset':
                        //console.log("offset", params[key]);
                        this.filters.offset = parseInt(params[key]);
                        this.queryParamsObj.offset = params[key];
                        break;

                    case 'range':
                        //console.log("range", params[key]);  
                        // if ( this.filters.tempRange !== parseInt(params[key] )) {
                        //     console.log("the range in the param query is different because we refreshed the page");
                        //     console.log("this.filters.range", this.filters.range );
                        //     console.log("this.filters.tempRange", this.filters.tempRange );
                        //     console.log("this.previousParamsObj", this.previousParamsObj);
                        // }
                        this.filters.tempRange = parseInt(params[key]);
                        this.filters.range = parseInt(params[key]);
                        this.queryParamsObj.range = params[key];
                        break;

                    case 'sort_by':
                        //console.log("sort_by", params[key]); 
                        break;

                    case 'sort_order':
                        //this.queryParamsObj.sort_order = params[key];
                        //console.log("sort_order", params[key]); 
                        break;

                    case 'rating':
                            let temp_rating = params[key] || null;
                            if (!temp_rating || !this.filters.rating) break;

                            let starRating = [];
                            this.filters.rating.map((item) => {
                                if (item.selected) {
                                    starRating.push(item.id);
                                }
                            });

                            if ( !!starRating && starRating.length > 0  ) {
                                this.queryParamsObj.admin_rating = starRating;
                            } else {
                                delete this.queryParamsObj.admin_rating; 
                            }
                            break;

                    case 'admin_rating':
                        let admin_rating = params[key] || null;
                        if (!admin_rating || !this.filters.rating) break;
                        admin_rating = admin_rating.split(',');
                        this.queryParamsObj.admin_rating = admin_rating;
                        for (let i = this.filters.rating.length; i--;) {
                            this.filters.rating[i].selected = admin_rating.indexOf(this.filters.rating[i].id + '') >= 0;
                        }
                        break;

                }
                if ( params['range'] ) {
                    this.filters.tempRange = parseInt(params['range']);
                    this.filters.range = parseInt(params['range']);
                    this.queryParamsObj.range = params['range'];
                } else {
                    this.filters.tempRange = 25;
                    this.filters.range = 25;
                    this.queryParamsObj.range = 25;
                }
                if (params['sort_by'] && params['sort_order']) {

                    this.queryParamsObj.sort_order = params['sort_order'];
                    this.queryParamsObj.sort_by = params['sort_by'];

                    if (!Array.isArray(this.filters.sorting) || !this.filters.sorting.length) return;

                    for (let i = this.filters.sorting.length; i--;) {
                        this.filters.sorting[i].type === params['sort_by']
                            && this.filters.sorting[i].dir === params['sort_order']
                            && (this.filters.sorting[i].selected = true);
                    }
                } else {
                    this.queryParamsObj.sort_order = 'asc';
                    this.queryParamsObj.sort_by = 'distance';
                }
            }

            // console.log("!this.ui.hasRatingBeenApplied", !this.ui.hasRatingBeenApplied);

            // if (!this.ui.hasRatingBeenApplied) {
            //     if (this.filters && this.filters.rating) {
            //         for (let i = this.filters.rating.length; i--; this.filters.rating[i].selected = false) {
            //         }
            //     }
            // }

            routerHistory.replace('/properties?' + this.queryParamsString);
            return this.RootStore.propListModel.updatePropertyList(this.queryParamsObj)
            .then(result => {
                result.hasOwnProperty('offset') && runInAction(() => {
                });

                result.hasOwnProperty('items') && runInAction(() => {
                    this.RootStore.propListModel.propertyList = this.RootStore.propListModel.propertyList;
                });

                
            });
        }
    }

    updatePropertyList() {
        return this.RootStore.propListModel.updatePropertyList(this.queryParamsObj)
            .then(result => {
                result.hasOwnProperty('offset') && runInAction(() => {
                });

                result.hasOwnProperty('items') && runInAction(() => {
                    this.RootStore.propListModel.propertyList = this.RootStore.propListModel.propertyList;
                });
        });
    }


    // for (let param of params) {
    //   switch (param) {
    //     //@Todo realise rest of params
    //     case 'min_rate':
    //       break;

    //     case 'max_rate':
    //       break;

    //     case 'arrival_date':
    //       this.filters.arrival_date = urlParams.get(param).toString();
    //       this.queryParamsObj.arrival_date = urlParams.get(param);
    //       break;


    //     case 'departure_date':
    //       this.filters.departure_date = urlParams.get(param).toString();
    //       this.queryParamsObj.departure_date = urlParams.get(param);
    //       break;


    //     case 'long':
    //       this.filters.long = parseFloat(urlParams.get(param) as string);
    //       this.queryParamsObj.long = urlParams.get(param);
    //       break;

    //     case 'lat':
    //       this.filters.lat = parseFloat(urlParams.get(param) as string);
    //       this.queryParamsObj.lat = urlParams.get(param);
    //       break;

    //     case 'range':
    //       this.queryParamsObj.range = urlParams.get(param);
    //       this.filters.range = parseInt(urlParams.get(param));
    //       break;

    //     case 'promoCode':
    //       this.queryParamsObj.promoCode = urlParams.get(param);
    //       this.filters.promoCode = urlParams.get(param);
    //       break;

    //     case 'apartment_type':
    //       let apartment_types: string | string[] | null = urlParams.get(param) || null;
    //       if (!apartment_types || !this.filters.apartmentTypes) break;
    //       apartment_types = apartment_types.split(',');

    //       this.queryParamsObj.apartment_type = apartment_types;
    //       for (let i = this.filters.apartmentTypes.length; i--;) {
    //         this.filters.apartmentTypes[i].selected = apartment_types.indexOf(this.filters.apartmentTypes[i].name + '') >= 0;
    //       }
    //       break;

    //     case 'amenities':
    //       let amenities: string | string[] | null = urlParams.get(param) || null;
    //       if (!amenities || !this.filters.amenities) break;
    //       amenities = amenities.split(',');

    //       this.queryParamsObj.amenities = amenities;
    //       for (let i = this.filters.amenities.length; i--;) {
    //         this.filters.amenities[i].selected = amenities.indexOf(this.filters.amenities[i].id + '') >= 0;
    //       }
    //       break;

    //     case 'admin_rating':
    //       let admin_rating: string | string[] | null = urlParams.get(param) || null;
    //       if (!admin_rating || !this.filters.rating) break;
    //       admin_rating = admin_rating.split(',');

    //       this.queryParamsObj.admin_rating = admin_rating;
    //       for (let i = this.filters.rating.length; i--;) {
    //         this.filters.rating[i].selected = admin_rating.indexOf(this.filters.rating[i].id + '') >= 0;
    //       }
    //       break;

    //     case 'city_name':
    //       this.filters.city_name = urlParams.get(param) || '';
    //       this.queryParamsObj.city_name = urlParams.get(param) || '';
    //       break;

    //     case 'country_name':
    //       this.filters.country_name = urlParams.get(param) || '';
    //       this.queryParamsObj.country_name = urlParams.get(param) || '';
    //       break;

    //     case 'state_name':
    //       this.filters.state_name = urlParams.get(param) || '';
    //       this.queryParamsObj.state_name = urlParams.get(param) || '';
    //       break;

    //     case 'page':
    //       this.filters.page = Number(urlParams.get(param)) || 1;
    //       this.queryParamsObj.page = urlParams.get(param) || '';
    //       break;

    //     case 'search_reference':
    //       this.filters.search_reference = urlParams.get(param) || '';
    //       this.queryParamsObj.search_reference = urlParams.get(param) || '';
    //       break;

    //     case 'include_oscar':
    //       this.filters.include_oscar = Number(urlParams.get(param)) || 0;
    //       this.queryParamsObj.include_oscar = urlParams.get(param) || 0;
    //       break;
    //   }
    // }

    // if (urlParams.has('sort_by') && urlParams.has('sort_order')) {
    //   if (!Array.isArray(this.filters.sorting) || !this.filters.sorting.length) return;

    //   this.queryParamsObj.sort_order = urlParams.get('sort_order');
    //   this.queryParamsObj.sort_by = urlParams.get('sort_by');

    //   for (let i = this.filters.sorting.length; i--;) {
    //     this.filters.sorting[i].type === urlParams.get('sort_by')
    //       && this.filters.sorting[i].dir === urlParams.get('sort_order')
    //       && (this.filters.sorting[i].selected = true);
    //   }
    // } else {
    //   this.queryParamsObj.sort_order = 'desc';
    //   this.queryParamsObj.sort_by = 'distance';
    // }
    //return true;
    //}


    /**
     * Updates sorting data
     * @param {number} id
     * @returns void
     */
    @action.bound
    updateSorting(id: number, routerHistory: any) {
        
        //console.log("updateSorting id", id);
       // console.log("updateSorting routerHistory", routerHistory);
        
        if (!Array.isArray(this.filters.sorting)) return;
        for (let i = this.filters.sorting.length; i--;) {
            this.filters.sorting[i].selected = false;
            this.filters.sorting[i].id === id && (this.filters.sorting[i].selected = true);
        }
        this.filters.sortType = id;
        this.filters.page = 1;
        this.filters.offset = 0;
        this.updateQueryParams();

        // here is where we want to decide if we are using query parameters
        // or if we are using the state machine.
        if ( this.filters.promo_code ) {
            this.searchWithCodeAndFilters(true);
        } else {
            this.searchWithQueryParams(this.filters, this.history);
        }
    }

    /**
     * Updates the number of guests
     * @param {number} id number of guests object id
     * todo move to BookingModel
     */
    @action.bound
    updateNumberOfGuests(id: number) {
        if (!Array.isArray(this.filters.numberOfGuests)) return;

        for (let i = this.filters.numberOfGuests.length; i--;) {
            this.filters.numberOfGuests[i].selected = false;
            this.filters.numberOfGuests[i].id === id && (this.filters.numberOfGuests[i].selected = true);
            ;
        }
    }

    /**
     * Toggle visibility for promo code modal
     */
    @action.bound
    togglePromoCodeVisibility() {
        this.ui.isPromoCodeModalVisible = !this.ui.isPromoCodeModalVisible;
        //console.log("togglePromoCodeVisibility() this.ui.isPromoCodeModalVisible", this.ui.isPromoCodeModalVisible );
    }

    @action.bound
    toggleShowStarRatingModal() {
        this.ui.showStarRatingModal = !this.ui.showStarRatingModal;
        //console.log("toggleShowStarRatingModal() this.ui.showStarRatingModal", this.ui.showStarRatingModal);
    }

    @action.bound
    async checkForValidateCode () : Promise<boolean>{
        const code = this.filters.promo_code;
        if (code && code.length > 5 ) {
            await Properties.validateCode(code)
            .then(res => {
                    if (!res.data.data.has_error) {
                        this.setValidPromoCode(true);
                        return true;
                    } else { 
                        this.setValidPromoCode(false);
                        return false;
                    }

            })
            .catch(e => {
                console.log(e);
            });
        }
        return;
                
    }

    /**
     * Updates promoCode data
     * @param {string} code
     */
    @action.bound
    updatePromoCode(code: string) {
        this.filters.promoCode = code;
        this.filters.promo_code = code;
        if (code && code.length > 5 ) {
            Properties.validateCode(code)
            .then(res => {
                runInAction(() => {
                    // console.log("updatePromoCode res.data", res.data);
                    if (!res.data.data.has_error) {
                        this.setValidPromoCode(true);
                        // we will need to base this off data when we are ready .
                        const isClientBilling = (res.data.data.configuration.payment_type === "client" );
                        const hide_rates = (res.data.data.configuration.hide_rates === 1 );
                        const maxDays = res.data.data.configuration.max_days;
                        this.filters.is_client_billing = isClientBilling;
                        this.filters.hide_rates = hide_rates;
                        this.filters.max_days = maxDays;
                        this.RootStore.bookingModel.setPromoCode(this.filters.promoCode);
                        if ( res.data.data.configuration.bedroom_type !== null ) {
                            this.filters.restricted_bedroom_type.push(res.data.data.configuration.bedroom_type);
                            switch( res.data.data.configuration.bedroom_type  ) {
                                case "Studio": 
                                {
                                    this.updateApartmentTypes( 1 , true);
                                    break;
                                }
                                case "1 Bedroom": 
                                {
                                    this.updateApartmentTypes( 2 , true);
                                    break;
                                }
                                case "2 Bedroom": 
                                {
                                    this.updateApartmentTypes( 3 , true);
                                    break;
                                }
                                case "3 Bedroom": 
                                {
                                    this.updateApartmentTypes( 4 , true);
                                    break;
                                }
                            }
                        } else {
                            this.filters.restricted_bedroom_type.push("Studio", "1 Bedroom", "2 Bedroom", "3 Bedroom" );
                            this.updateApartmentTypes( 1 , true);
                            this.updateApartmentTypes( 2 , true);
                            this.updateApartmentTypes( 3 , true);
                            this.updateApartmentTypes( 4 , true);
                        }
                        this.updateTempRange(res.data.data.configuration.miles || 15);
                        // this.setLatLong(res.data.data.configuration.lat, res.data.data.configuration.long );
                        // this.updatePromoAddress(res.data.data.configuration.location);
                        // this.filters.restricted_bedroom_type = "1 Bedroom";
                        // console.log("this.filters.restricted_bedroom_type", this.filters.restricted_bedroom_type);
                    
                        // Please leave for test data 
                        /////////////////////////////////////////////////////////////
                        //     const lead_info =  {
                        //         "transferee_name": "dane tersteson",
                        //         "transferee_email": "dtester@test.com",
                        //         "arrival_date": "2022-08-12",
                        //         "departure_date": "2022-10-01",
                        //         "occupants": null,
                        //         "parking_type": "Tandem Garage",
                        //         "parking_spots": 2
                        //     }
                        //    const pets =  [
                        //           {
                        //             "id": 25440,
                        //             "category": "Dog",
                        //             "breed": "Airedale Terrier",
                        //             "weight": 10,
                        //             "fee_charged_to": "0",
                        //             "breed_other": null,
                        //             "created_at": "2022-08-01T17:07:49.000000Z",
                        //             "updated_at": "2022-08-01T17:07:49.000000Z",
                        //             "weight_units": "Kilos"
                        //           }
                        //         ];

                        //     const tempOccupants = {
                        //         number_additional_adults: 1,
                        //         number_additional_children: 1,
                        //         total_number_of_occupants: 2,
                        //         occupants_notes: "blah"
                        //     }

                        //     const arrivalDate = lead_info.arrival_date;
                        //     const departureDate = lead_info.departure_date;
                        //     const parkingType = lead_info.parking_type;
                        //     const occupants = lead_info.occupants;
                        //     const occupants = tempOccupants;
                        //     const parkingSpots = lead_info.parking_spots;
                        //     const transfereeName = lead_info.transferee_name;
                        //     const transfereeEmail = lead_info.transferee_email;

                        ////////////////////////////////////////////////////////////////////////

                        const arrivalDate = res.data.data.lead_info.arrival_date;
                        const departureDate = res.data.data.lead_info.departure_date;
                        const parkingType = res.data.data.lead_info.parking_type;
                        const occupants = res.data.data.lead_info.occupants;
                        const parkingSpots = res.data.data.lead_info.parking_spots;
                        const pets = res.data.data.lead_info.pets;
                        const transfereeName = res.data.data.lead_info.transferee_name;
                        const transfereeEmail = res.data.data.lead_info.transferee_email;

                        const clientFileNumber = res.data.data.configuration.client_file_number;
                        const companyName = res.data.data.configuration.company_name;


                        if ( !!clientFileNumber ) {
                            this.RootStore.bookingModel.updateSearchValue("client_file_number", clientFileNumber );
                        }

                        if ( !!companyName ) {
                            this.RootStore.bookingModel.updateSearchValue("company_name", companyName );
                        }
                        // NDM-6804 - irst and last name - stop auto filling on search form when code is added
                        // if ( !!transfereeName ) {
                        //     this.RootStore.bookingModel.updateSearchValue("full_name", transfereeName );
                        //     this.updateSearchValue("full_name", transfereeName );
                        // }

                        if ( !!transfereeEmail ) {
                            this.RootStore.bookingModel.updateSearchValue("email", transfereeEmail );
                            this.updateSearchValue("email", transfereeEmail );
                        }

                        if ( !!arrivalDate && !!departureDate ) {
                            this.updateDates({start: arrivalDate, end:departureDate });
                        }

                        if ( !!parkingType && !!parkingSpots ) {
                            this.RootStore.bookingModel.updateSearchValue("parking_type", parkingType );
                            this.RootStore.bookingModel.updateSearchValue("number_parking_spots", parkingSpots );
                            this.RootStore.uiModel.setUserNeedsParking(true);
                        }
                        
                        if ( !!pets && pets.length > 0 ) {
                            this.RootStore.bookingModel.setPets(pets);
                            this.RootStore.uiModel.setUserHasPets(true);
                        }

                        if ( !!occupants && occupants.total_number_of_additional_occupants > 0) {
                            this.RootStore.bookingModel.updateSearchValue("number_additional_adults", occupants.number_additional_adults );
                            this.RootStore.bookingModel.updateSearchValue("number_additional_children", occupants.number_additional_children );
                            this.RootStore.bookingModel.updateSearchValue("total_number_of_additional_occupants", occupants.total_number_of_additional_occupants );
                            this.RootStore.bookingModel.updateSearchValue("occupants_notes", occupants.occupants_notes );
                            
                            const show  = ( !!occupants && occupants.total_number_of_additional_occupants > 0 )? true : false;
                            this.RootStore.uiModel.setUserHasAdditionalOccupants(show);
                        }
                        
                        this.RootStore.bookingModel.setPromoCode(this.filters.promoCode);

                    } else { 
                        this.setPromoCodeErrorMessage(res.data.data.message);
                        this.filters.promoCode = code;
                        this.filters.promo_code = code;
                        // this.RootStore.bookingModel.setPromoCode(this.filters.promoCode)
                        this.setValidPromoCode(false);
                        // console.log("This code is not valid");
                    }
                })
            })
            .catch(e => {
                console.log(e);
            });
        }
        
        //this.togglePromoCodeVisibility();
    }

    @action.bound
    submitPromoCode(code: string) {
        this.ui.usePromoCode = true;
        this.togglePromoCodeVisibility();
        this.updateQueryParams();
    }

    /**
     * Clear promo code data
     */
    @action.bound
    resetPromoCode() {
        this.filters.promoCode = '';
        this.RootStore.bookingModel.ui.validPromoCode = false;
        this.updateQueryParams();
    }

    /**
     * Set Same Search
     */
    @action.bound
    setSameSearch(isSame: boolean) {
        this.sameSearch = isSame;
    }

    @action.bound
    updatePage(page: number, routerHistory: any) {
        this.previousPage = this.filters.page;
        //("updatePage", page);
        if (this.previousPage !== page) {
            this.filters.page = page;
            this.filters.offset = (page - 1) * this.filters.perPage;
            if ( !!this.filters.promo_code ) {
                this.updateQueryParams();
                this.searchWithCodeAndFilters(true);
            } else {
                this.updateQueryParams();
                this.searchWithQueryParams(this.filters, routerHistory);
            }
        }
    }

    // todo double check reset on all filters
    @action.bound
    resetFilter(name: string, routerHistory: any) {
        
        switch (name) {
            case 'apartmentType':
                if (this.filters && this.filters.apartmentTypes) {
                    for (let i = this.filters.apartmentTypes.length; i--; this.filters.apartmentTypes[i].selected = false) {
                    }
                }
                break;
            case 'rateRange':
                this.filters.rate.value = { min: 0, max: 1000 };
                break;
            case 'amenities':
                if (this.filters && this.filters.amenities)
                    for (let i = this.filters.amenities.length; i--; this.filters.amenities[i].selected = false) {
                    }
                break;
            case 'starRating':
                if (this.filters && this.filters.rating)
                    for (let i = this.filters.rating.length; i--; this.filters.rating[i].selected = false) {
                    }
                this.ui.hasRatingBeenApplied = false;
                break;
            case 'date':
                this.filters.arrival_date = undefined;
                this.filters.departure_date = undefined;
                break;
            case 'distance':
                this.filters.range = 24;
                this.filters.tempRange = 24
                break;
            case 'promoCode':
                this.filters.promoCode = '';
            //console.log("resetFilter() this.filters.promoCode", this.filters.promoCode );
            case 'page':
                this.filters.page = 1;
                break;
            case 'search_reference':
                this.filters.search_reference = '';
                break;
            case 'include_oscar':
                this.filters.include_oscar = 0;
                break;
        }
        this.searchWithQueryParams(this.filters, this.history);
        //this.applyFilters(routerHistory);
    }

    @action.bound
    resetModel() {

        console.log("resetModel");

        if (this.filters && this.filters.apartmentTypes) {
            for (let i = this.filters.apartmentTypes.length; i--; this.filters.apartmentTypes[i].selected = false) {
            }
        }
        this.filters.rate.value = { min: 0, max: 1000 };
        if (this.filters && this.filters.amenities)
            for (let i = this.filters.amenities.length; i--; this.filters.amenities[i].selected = false) {
            }
        if (this.filters && this.filters.rating)
            for (let i = this.filters.rating.length; i--; this.filters.rating[i].selected = false) {
            }
        if (this.filters && this.filters.tempRating)
            for (let i = this.filters.tempRating.length; i--; this.filters.tempRating[i].selected = false) {
            }
        this.filters.rating = this.ratingArray,
        this.filters.arrival_date = undefined;
        this.filters.departure_date = undefined;
        this.filters.range = 24;
        this.filters.promoCode = '';
        this.filters.page = 1;
        this.filters.search_reference = '';
        this.filters.include_oscar = 0;
        this.filters.lat = 0;
        this.filters.long = 0;
        this.filters.address = '';
        this.filters.perPage = 24;
        this.filters.offset = 0;
        this.filters.sorting = Properties.misc.getSortingOptions();
        this.filters.promoCode = "";
        this.filters.promo_code = "";
        this.filters.country_name = "";
        this.filters.email = "";
        this.filters.promo_code = "";
        this.filters.email = "";
        this.filters.searchValidationErrors = ["full_name", "email", "arrival_date", "departure_date", "apartment_type"];
        this.filters.requiredSearchInfoFields = ["full_name", "email", "promo_code", "arrival_date", "departure_date", "apartment_type"];
        this.filters.full_name = "";
        this.filters.apartment_type = [];
        this.filters.restricted_bedroom_type = [];
        this.filters.max_days = undefined;
       
       
        this.ui.isPromoCodeModalVisible = false;
        this.ui.showAdditionalResultsModal = false;
        this.ui.showAdditionalResultsMessage = false;
        this.ui.hasOscarResults = false;
        this.ui.showStarRatingModal = false;
        this.ui.showOscar = false;
        this.ui.validPromoCode = false;
        this.ui.usePromoCode = false;
        this.ui.promo_code_error_message = "";
    }

    /**
     * Applies all filters and performs server request
     * @param {iStayDates} newDates
     * @returns void
     */
    @action.bound
    applyFilters(routerHistory: any) {
        if ( routerHistory !== null  ) {
            this.history = routerHistory;
            this.updateQueryParams();
            routerHistory.replace('/properties?' + this.queryParamsString);
            this.updatePropertyList();
        }

        // this to trigger pipeline
    }

    @action.bound
    applyOscarFilters(routerHistory: any) {
        this.history = routerHistory;
        this.updateQueryParams();
        routerHistory.push('/properties?' + this.queryParamsString);
        this.RootStore.applyOscarFilters(routerHistory)
    }

    @action.bound
    setPromoCodeErrorMessage(message: string) {
        this.ui.promo_code_error_message = message;
    }

    /**
     * Applies same filters and no server request
     * @returns void
     */
    @action.bound
    applySameFilters(routerHistory: any) {
        routerHistory.push('/properties?' + this.queryParamsString);
    }

    @action.bound
    updateSearchValue(valueName: string, value: any) {
        if (valueName in this.filters) {
            (this.filters as any)[valueName] = value;
            this.validateSearchField(valueName);
        }
	}

    @action.bound
    applySearchForm() {
        this.filters.requiredSearchInfoFields.forEach((field) => this.validateSearchField(field));
        if ( !this.ui.validPromoCode || (this.filters.searchValidationErrors.length > 0) ) {
            return;
        } else {
            this.RootStore.uiModel.togglePromoCodeVisibility();
        }
    }

    @action.bound
    validateSearchField(fieldName: string): boolean {
        let retVal = false;
        
        const fieldValue = (this.filters as any)[fieldName];
        if (fieldName === "email") {
            const emailRule = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            
            
            if (!emailRule.test(fieldValue.toString().toLowerCase())) {
                //console.log("I should be causing an error", fieldValue);
                if (!this.filters.searchValidationErrors.includes("email")) {
                    this.filters.searchValidationErrors.push("email");
                }
            } else {
                const index = this.filters.searchValidationErrors.findIndex((item) => item === "email");
                if (index > -1) {
                    this.filters.searchValidationErrors.splice(index, 1);
                }
                retVal = true;
            }
        }  else if (fieldName === "apartment_type" ) {
            if (fieldValue.length === 0) {
                if (!this.filters.searchValidationErrors.includes(fieldName)) {
                    this.filters.searchValidationErrors.push(fieldName);
                }
            } else {
                const index = this.filters.searchValidationErrors.findIndex((item) => item === fieldName);
                if (index > -1) {
                    this.filters.searchValidationErrors.splice(index, 1);
                }
                retVal = true;
            }
        }
         else {
            if (this.filters.requiredSearchInfoFields.includes(fieldName))
            {
                if (!fieldValue) {
                    if (!this.filters.searchValidationErrors.includes(fieldName)) {
                        this.filters.searchValidationErrors.push(fieldName);
                    }
                } else {
                    const index = this.filters.searchValidationErrors.findIndex((item) => item === fieldName);
                    if (index > -1) {
                        this.filters.searchValidationErrors.splice(index, 1);
                    }
                    retVal = true;
                }
            }
        }
        return retVal;
    }

    @action.bound
    setLatLong(lat: any, long: any) {
        this.filters.lat = lat;
        this.filters.long = long;
    }

    @computed
    get queryParamsString() {
        return Object.keys(this.queryParamsObj).map(
            key => key + '=' + this.queryParamsObj[key]
        ).join('&');
    }

    /**
     * Returns selected sorting object
     * @return {iSorting}
     */
    @computed
    get selectedSorting() {
        if (!Array.isArray(this.filters.sorting)) return;
        for (let i = this.filters.sorting.length; i--;) {
            if (this.filters.sorting[i].selected)
                return this.filters.sorting[i]  // bucause of typeScript null check made me do it
        }
    }

    /**
     * Returns selected apartment type names in a single string
     * @returns {string}
     */
    @computed
    get selectedApartmentTypesString() {
        return Array.isArray(this.filters.apartmentTypes)
            && this.filters.apartmentTypes.map(type => {
                if (type.selected) return type.name
            }).join(' ').trim();
    }

    /**
     * Returns selected dates formatted as a string
     * @returns {string}
     */
    @computed
    get selectedDatesString() {
        let tempFilters = { ...this.filters };
        return tempFilters.arrival_date && tempFilters.departure_date
            ? `${moment(tempFilters.arrival_date).format('MMM DD')} - ${moment(tempFilters.departure_date).format('MMM DD')}`
            : '';
    }
    /**
     * Returns selected rating formatted string
     * @returns {string}
     */
    @computed
    get selectedStarsString() {
        if (!this.filters || !Array.isArray(this.filters.rating)) return '';
        return this.filters.rating.map((item) => {
            if (item.selected) return item.name
        }).join(' ').trim();
    }

    /**
     * Returns selected amenities in a single string
     * @returns {string}
     */
    @computed
    get selectedAmenitiesString() {
        return Array.isArray(this.filters.amenities)
            && this.filters.amenities.map(type => {
                if (type.selected) return type.name
            }).join(' ').trim();
    }

    /**
     * Returns selected distance in a single string
     * @returns {string}
     */

    @computed
    get selectedDistanceString() {
        return this.filters.tempRange + ' miles';
    }

    /**
     * Returns selected range in a single string
     * @returns {string}
     */
    @computed
    get selectedRateRangesString() {
        if (!this.filters || !this.filters.rate) return false;
        const returnStr = `$${this.filters.rate.value.min} - $${this.filters.rate.value.max}`;
        return returnStr;
    }
}

export default Search;
