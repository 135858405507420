import { observable, action, computed, runInAction, observe } from "mobx";
import Search from "./Property/Search/";
import { PropertiesList } from "./Property/PropertiesList";
import { SingleProperty } from "./Property/SingleProperty";
import { RequestInfo } from "./Request/RequestInfo";
import { Booking } from "./Booking/Booking";
import { UI } from "./UI/UI";
import { create } from 'mobx-persist';

const hydrate = create({
    storage: localStorage,
    jsonify: true,
});

export class RootStore {

    constructor() {
        this.searchModel = new Search(this);
        this.propListModel = new PropertiesList(this);
        this.propertyModel = new SingleProperty(this);
        this.requestModel = new RequestInfo(this);
        this.bookingModel = new Booking(this);
        this.uiModel = new UI(this);
        this.previousQueryParamsObj = {};

        Promise.all([
            hydrate('searchModel', this.searchModel),
            hydrate('propListModel', this.propListModel),
            hydrate('propertyModel', this.propertyModel),
            hydrate('requestModel', this.requestModel),
            hydrate('bookingModel', this.bookingModel),
            hydrate('uiModel', this.uiModel)
        ])
    }

    @observable
    public searchModel: Search;
    public propListModel: PropertiesList;
    public propertyModel: SingleProperty;
    public previousQueryParamsObj: any;
    public requestModel: RequestInfo;
    public bookingModel: Booking;
    public uiModel:UI;


    @action
    searchWithQueryParameters(params: any, routerHistory: any) {
        this.searchModel.searchWithQueryParams(params, routerHistory );
    }

    @action
    searchWithCodeAndFilters() {
        this.searchModel.searchWithCodeAndFilters();
    }

    @action
    applyOscarFilters(routerHistory: any) {
        return this.propListModel.updatePropertyList(this.searchModel.queryParamsObj)
            .then(result => {
                result.hasOwnProperty('offset') && runInAction(() => {
                    this.searchModel.queryParamsObj.page = (result.offset / result.limit) + 1;
                });

                result.hasOwnProperty('items') && runInAction(() => {
                    this.propListModel.propertyList = this.propListModel.propertyList;
                });

                //console.log("Root store applyOscarFilters") ;  

                routerHistory.push('/properties?' + this.searchModel.queryParamsString);
            }
        );
    }

    @action
    applyPageFilters(routerHistory: any) {
        return this.propListModel.updatePropertyList(this.searchModel.queryParamsObj)
            .then(result => {
                result.hasOwnProperty('offset') && runInAction(() => {
                    this.searchModel.queryParamsObj.page = (result.offset / result.limit) + 1;
                });

                result.hasOwnProperty('items') && runInAction(() => {
                    this.propListModel.propertyList = this.propListModel.propertyList;
                });
                routerHistory.push('/properties?' + this.searchModel.queryParamsString);
            }
        );
    }


    @action
    applySameFilters(routerHistory: any) {
        this.searchModel.applySameFilters(routerHistory);
        routerHistory.push('/properties?' + this.searchModel.queryParamsString);
    }

    @action
    clearMobxPersistModels() {
        this.searchModel.resetModel();
        this.propListModel.resetModel();
        this.propertyModel.resetModel();
        this.requestModel.resetModel();
        this.bookingModel.resetModel();
        this.uiModel.resetModal();
    }
}

export default new RootStore();