import { observable, action, computed, runInAction } from "mobx";
import { iUi } from "./interfaces";
import { UserAgent } from './agent';
import { RootStore } from "../RootStore";
import { AccordionItem } from "react-accessible-accordion";
import { persist } from 'mobx-persist';

export const PARKING_TYPE = {
    "Space(s) Included": "Space(s) Included",
    "On-Site Parking Garage": "On-Site Parking Garage",
    "Off-Site Parking Garage": "Off-Site Parking Garage",
    "Uncovered Parking": "Uncovered Parking",
    "Uncovered Open Parking": "Uncovered Open Parking",
    "Uncovered Reserved Parking": "Uncovered Reserved Parking",
    "Covered Parking": "Covered Parking",
    "Garage Reserved Parking": "Garage Reserved Parking",
    "Garage Unreserved Parking": "Garage Unreserved Parking",
    "Private Garage": "Private Garage",
    "Tandem Garage": "Tandem Garage",
    "Detached Tandem Garage": "Detached Tandem Garage",
    "Valet Parking": "Valet Parking",
    "Unknown": "Unknown",
    "No Preference": "No Preference",
    "None": "None"
};

export const PET_TYPE = {
    Dog: "Dog",
    Cat: "Cat",
    Other: "Other"
};

export const PET_WEIGHT_TYPE = {
    Lbs: "Lbs",
    kg: "kg",
};

export const PET_BREED = {
    "Other": "Other",
    "Unknown": "Unknown",
    "Affenpinscher": "Affenpinscher",
    "Afghan": "Afghan",
    "Airedale Terrier": "Airedale Terrier",
    "Akita Dog Breed": "Akita Dog Breed",
    "Alaskan Malamute Dogs": "Alaskan Malamute Dogs",
    "American Eskimo Dog": "American Eskimo Dog",
    "American Foxhound": "American Foxhound",
    "American Staffordshire Terrier": "American Staffordshire Terrier",
    "American Water Spaniel": "American Water Spaniel",
    "Anatolian Shepherd Dogs": "Anatolian Shepherd Dogs",
    "Australian Cattle Dog": "Australian Cattle Dog",
    "Australian Shepherd": "Australian Shepherd",
    "Australian Terrier": "Australian Terrier",
    "Basenji": "Basenji",
    "Basset Hound": "Basset Hound",
    "Beagle": "Beagle",
    "Bearded Collie": "Bearded Collie",
    "Bedlington Terrier": "Bedlington Terrier",
    "Belgian Malinois": "Belgian Malinois",
    "Belgian Sheepdog": "Belgian Sheepdog",
    "Belgian Tervuren": "Belgian Tervuren",
    "Bernese Mountain Dogs": "Bernese Mountain Dogs",
    "Bichon Frise": "Bichon Frise",
    "Black And Tan Coonhound": "Black And Tan Coonhound",
    "Black Russian Terriers": "Black Russian Terriers",
    "Bloodhound": "Bloodhound",
    "Border Collie": "Border Collie",
    "Border Terrier": "Border Terrier",
    "Borzoi ": "Borzoi",
    "Boston Terrier": "Boston Terrier",
    "Bouvier_des_Flandres": "Bouvier_des_Flandres",
    "Briard": "Briard",
    "Brittany": "Brittany",
    "Brussels Griffon": "Brussels Griffon",
    "Bulldog": "Bulldog",
    "Bull Mastiff Dog": "Bull Mastiff Dog",
    "Bull Terrier": "Bull Terrier",
    "Cairn Terrier": "Cairn Terrier",
    "Canaan Dog": "Canaan Dog",
    "Cardigan Welsh Corgi": "Cardigan Welsh Corgi",
    "Cavalier King Charles Spaniel": "Cavalier King Charles Spaniel",
    "Chesapeake Bay Retriever": "Chesapeake Bay Retriever",
    "Chihuahua": "Chihuahua",
    "Chinese Crested": "Chinese Crested",
    "Chinese Shar Pei": "Chinese Shar Pei",
    "Chow Chow": "Chow Chow",
    "Clumber Spaniel": "Clumber Spaniel",
    "Cocker Spaniel": "Cocker Spaniel",
    "Collie": "Collie",
    "Curly-coated Retriever": "Curly-coated Retriever",
    "Dachshund": "Dachshund",
    "Dalmatian": "Dalmatian",
    "Dandie Dinmont Terrier": "Dandie Dinmont Terrier",
    "Doberman Pinscher Dogs": "Doberman Pinscher Dogs",
    "English Bull Dogs": "English Bull Dogs",
    "English Cocker Spaniel": "English Cocker Spaniel",
    "English Foxhound": "English Foxhound",
    "English Setter": "English Setter",
    "English Springer Spaniel": "English Springer Spaniel",
    "English Toy Spaniel": "English Toy Spaniel",
    "Field Spaniel": "Field Spaniel",
    "Finnish Spitz": "Finnish Spitz",
    "Flat-Coated Retriever": "Flat-Coated Retriever",
    "French Bull Dogs": "French Bull Dogs",
    "German Shorthaired Pointer ": "German Shorthaired Pointer",
    "German Shepherd Dog": "German Shepherd Dog",
    "German Wirehaired Pointer": "German Wirehaired Pointer",
    "Glen of Imaal Terrier ": "Glen of Imaal Terrier",
    "Golden Retriever": "Golden Retriever",
    "Gordon Setter": "Gordon Setter",
    "Great Dane": "Great Dane",
    "Greyhound": "Greyhound",
    "Harrier ": "Harrier",
    "Havanese": "Havanese",
    "Herding Dog Breeds": "Herding Dog Breeds",
    "Hound Dog Breeds": "Hound Dog Breeds",
    "Ibizan Hound": "Ibizan Hound",
    "Irish Setter": "Irish Setter",
    "Irish Terrier": "Irish Terrier",
    "Irish Water Spaniel": "Irish Water Spaniel",
    "Irish Wolfhound": "Irish Wolfhound",
    "Italian Greyhound": "Italian Greyhound",
    "Jack Russell Terrier": "Jack Russell Terrier",
    "Japanese Chin": "Japanese Chin",
    "Kerry Blue Terrier": "Kerry Blue Terrier",
    "Keeshonds": "Keeshonds",
    "Labrador Retriever": "Labrador Retriever",
    "Lakeland Terrier": "Lakeland Terrier",
    "Landseer Newfoundland": "Landseer Newfoundland",
    "Lhasa Apso Dogs": "Lhasa Apso Dogs",
    "Maltese": "Maltese",
    "Manchester Terrier": "Manchester Terrier",
    "Toy Manchester Terrier": "Toy Manchester Terrier",
    "Miniature Bull Terrier": "Miniature Bull Terrier",
    "Miniature Dog Breeds": "Miniature Dog Breeds",
    "Miniature Pinscher": "Miniature Pinscher",
    "Miniature Schnauzer": "Miniature Schnauzer",
    "non-sporting group": "non-sporting group",
    "Norfolk Terrier": "Norfolk Terrier",
    "Norwegian Elkhound": "Norwegian Elkhound",
    "Norwich Terrier": "Norwich Terrier",
    "Nova Scotia Duck Tolling Retriever": "Nova Scotia Duck Tolling Retriever",
    "Old English Sheepdog": "Old English Sheepdog",
    "Otterhound": "Otterhound",
    "Papillon": "Papillon",
    "Parson Russell Terrier": "Parson Russell Terrier",
    "Pekingese": "Pekingese",
    "Pembroke Welsh Corgi": "Pembroke Welsh Corgi",
    "Petit Basset Griffon Vendeen": "Petit Basset Griffon Vendeen",
    "Pharaoh Hound": "Pharaoh Hound",
    "Pit Bull": "Pit Bull",
    "Polish Lowland Sheepdog": "Polish Lowland Sheepdog",
    "Pomeranian": "Pomeranian",
    "Poodle": "Poodle",
    "Pug": "Pug",
    "Puli": "Puli",
    "Rare Mastiff Dog Breeds": "Rare Mastiff Dog Breeds",
    "Rhodesian Ridgeback": "Rhodesian Ridgeback",
    "Rottweiler Dogs": "Rottweiler Dogs",
    "Saint Bernard Dogs": "Saint Bernard Dogs",
    "Saluki": "Saluki",
    "Scottish Deerhound": "Scottish Deerhound",
    "Scottish Terrier": "Scottish Terrier",
    "Sealyham Terrier": "Sealyham Terrier",
    "Shetland Sheepdog": "Shetland Sheepdog",
    "Shih Tzu Dogs": "Shih Tzu Dogs",
    "Silky Terrier": "Silky Terrier",
    "Skye Terrier ": "Skye Terrier",
    "Smooth Fox Terrier ": "Smooth Fox Terrier",
    "Soft Coated Wheaten Terrier ": "Soft Coated Wheaten Terrier",
    "Spinone Italiano ": "Spinone Italiano",
    "Sporting Dog Breeds": "Sporting Dog Breeds",
    "Staffordshire Bull Terrier": "Staffordshire Bull Terrier",
    "Sussex Spaniel": "Sussex Spaniel",
    "Terrier Dog Breeds": "Terrier Dog Breeds",
    "Toy Dog Breeds": "Toy Dog Breeds",
    "Toy Fox Terrier": "Toy Fox Terrier",
    "Vizsla": "Vizsla",
    "Weimaraner": "Weimaraner",
    "Welsh Springer Spaniel": "Welsh Springer Spaniel",
    "Welsh Terrier": "Welsh Terrier",
    "West Highland White Terrier": "West Highland White Terrier",
    "Whippet": "Whippet",
    "Wire Fox Terrier": "Wire Fox Terrier",
    "Wirehaired Pointing Griffon": "Wirehaired Pointing Griffon",
    "Working Dog Breeds": "Working Dog Breeds",
    "Xoloitzcuintle": "Xoloitzcuintle",
    "Yorkshire Terrier": "Yorkshire Terrier"
};

export const ORIGIN_COUNTRY = {
    "Afghanistan": "Afghanistan",
    "Albania": "Albania",
    "Algeria": "Algeria",
    "American Samoa": "American Samoa",
    "Andorra": "Andorra",
    "Angola": "Angola",
    "Anguilla": "Anguilla",
    "Antarctica": "Antarctica",
    "Antigua and Barbuda": "Antigua and Barbuda",
    "Argentina": "Argentina",
    "Armenia": "Armenia",
    "Aruba": "Aruba",
    "Australia": "Australia",
    "Austria": "Austria",
    "Azerbaijan": "Azerbaijan",
    "Bahamas": "Bahamas",
    "Bahrain": "Bahrain",
    "Bangladesh": "Bangladesh",
    "Barbados": "Barbados",
    "Belarus": "Belarus",
    "Belgium": "Belgium",
    "Belize": "Belize",
    "Benin": "Benin",
    "Bermuda": "Bermuda",
    "Bhutan": "Bhutan",
    "Bolivia (Plurinational State of)": "Bolivia (Plurinational State of)",
    "Bonaire, Sint Eustatius and Saba": "Bonaire, Sint Eustatius and Saba",
    "Bosnia and Herzegovina": "Bosnia and Herzegovina",
    "Botswana": "Botswana",
    "Bouvet Island": "Bouvet Island",
    "Brazil": "Brazil",
    "British Indian Ocean Territory": "British Indian Ocean Territory",
    "Brunei Darussalam": "Brunei Darussalam",
    "Bulgaria": "Bulgaria",
    "Burkina Faso": "Burkina Faso",
    "Burundi": "Burundi",
    "Cabo Verde": "Cabo Verde",
    "Cambodia": "Cambodia",
    "Cameroon": "Cameroon",
    "Canada": "Canada",
    "Cayman Islands": "Cayman Islands",
    "Central African Republic": "Central African Republic",
    "Chad": "Chad",
    "Chile": "Chile",
    "China": "China",
    "Christmas Island": "Christmas Island",
    "Cocos (Keeling) Islands": "Cocos (Keeling) Islands",
    "Colombia": "Colombia",
    "Comoros": "Comoros",
    "Congo": "Congo",
    "Congo (Democratic Republic of the)": "Congo (Democratic Republic of the)",
    "Cook Islands": "Cook Islands",
    "Costa Rica": "Costa Rica",
    "Croatia": "Croatia",
    "Cuba": "Cuba",
    "Curaçao": "Curaçao",
    "Cyprus": "Cyprus",
    "Czechia": "Czechia",
    "Côte d'Ivoire": "Côte d'Ivoire",
    "Denmark": "Denmark",
    "Djibouti": "Djibouti",
    "Dominica": "Dominica",
    "Dominican Republic": "Dominican Republic",
    "Ecuador": "Ecuador",
    "Egypt": "Egypt",
    "El Salvador": "El Salvador",
    "Equatorial Guinea": "Equatorial Guinea",
    "Eritrea": "Eritrea",
    "Estonia": "Estonia",
    "Ethiopia": "Ethiopia",
    "Falkland Islands (Malvinas)": "Falkland Islands (Malvinas)",
    "Faroe Islands": "Faroe Islands",
    "Fiji": "Fiji",
    "Finland": "Finland",
    "France": "France",
    "French Guiana": "French Guiana",
    "French Polynesia": "French Polynesia",
    "French Southern Territories": "French Southern Territories",
    "Gabon": "Gabon",
    "Gambia": "Gambia",
    "Georgia": "Georgia",
    "Germany": "Germany",
    "Ghana": "Ghana",
    "Gibraltar": "Gibraltar",
    "Greece": "Greece",
    "Greenland": "Greenland",
    "Grenada": "Grenada",
    "Guadeloupe": "Guadeloupe",
    "Guam": "Guam",
    "Guatemala": "Guatemala",
    "Guernsey": "Guernsey",
    "Guinea": "Guinea",
    "Guinea-Bissau": "Guinea-Bissau",
    "Guyana": "Guyana",
    "Haiti": "Haiti",
    "Heard Island and McDonald Islands": "Heard Island and McDonald Islands",
    "Holy See": "Holy See",
    "Honduras": "Honduras",
    "Hong Kong": "Hong Kong",
    "Hungary": "Hungary",
    "Iceland": "Iceland",
    "India": "India",
    "Indonesia": "Indonesia",
    "Iran (Islamic Republic of)": "Iran (Islamic Republic of)",
    "Iraq": "Iraq",
    "Ireland": "Ireland",
    "Isle of Man": "Isle of Man",
    "Israel": "Israel",
    "Italy": "Italy",
    "Jamaica": "Jamaica",
    "Japan": "Japan",
    "Jersey": "Jersey",
    "Jordan": "Jordan",
    "Kazakhstan": "Kazakhstan",
    "Kenya": "Kenya",
    "Kiribati": "Kiribati",
    "Korea (Democratic People's Republic of)": "Korea (Democratic People's Republic of)",
    "Korea (Republic of)": "Korea (Republic of)",
    "Kuwait": "Kuwait",
    "Kyrgyzstan": "Kyrgyzstan",
    "Lao People's Democratic Republic": "Lao People's Democratic Republic",
    "Latvia": "Latvia",
    "Lebanon": "Lebanon",
    "Lesotho": "Lesotho",
    "Liberia": "Liberia",
    "Libya": "Libya",
    "Liechtenstein": "Liechtenstein",
    "Lithuania": "Lithuania",
    "Luxembourg": "Luxembourg",
    "Macao": "Macao",
    "Macedonia (the former Yugoslav Republic of)": "Macedonia (the former Yugoslav Republic of)",
    "Madagascar": "Madagascar",
    "Malawi": "Malawi",
    "Malaysia": "Malaysia",
    "Maldives": "Maldives",
    "Mali": "Mali",
    "Malta": "Malta",
    "Marshall Islands": "Marshall Islands",
    "Martinique": "Martinique",
    "Mauritania": "Mauritania",
    "Mauritius": "Mauritius",
    "Mayotte": "Mayotte",
    "Mexico": "Mexico",
    "Micronesia (Federated States of)": "Micronesia (Federated States of)",
    "Moldova (Republic of)": "Moldova (Republic of)",
    "Monaco": "Monaco",
    "Mongolia": "Mongolia",
    "Montenegro": "Montenegro",
    "Montserrat": "Montserrat",
    "Morocco": "Morocco",
    "Mozambique": "Mozambique",
    "Myanmar": "Myanmar",
    "Namibia": "Namibia",
    "Nauru": "Nauru",
    "Nepal": "Nepal",
    "Netherlands": "Netherlands",
    "New Caledonia": "New Caledonia",
    "New Zealand": "New Zealand",
    "Nicaragua": "Nicaragua",
    "Niger": "Niger",
    "Nigeria": "Nigeria",
    "Niue": "Niue",
    "Norfolk Island": "Norfolk Island",
    "Northern Mariana Islands": "Northern Mariana Islands",
    "Norway": "Norway",
    "Oman": "Oman",
    "Pakistan": "Pakistan",
    "Palau": "Palau",
    "Palestine, State of": "Palestine, State of",
    "Panama": "Panama",
    "Papua New Guinea": "Papua New Guinea",
    "Paraguay": "Paraguay",
    "Peru": "Peru",
    "Philippines": "Philippines",
    "Pitcairn": "Pitcairn",
    "Poland": "Poland",
    "Portugal": "Portugal",
    "Puerto Rico": "Puerto Rico",
    "Qatar": "Qatar",
    "Romania": "Romania",
    "Russian Federation": "Russian Federation",
    "Rwanda": "Rwanda",
    "Réunion": "Réunion",
    "Saint Barthélemy": "Saint Barthélemy",
    "Saint Helena, Ascension and Tristan da Cunha": "Saint Helena, Ascension and Tristan da Cunha",
    "Saint Kitts and Nevis": "Saint Kitts and Nevis",
    "Saint Lucia": "Saint Lucia",
    "Saint Martin (French part)": "Saint Martin (French part)",
    "Saint Pierre and Miquelon": "Saint Pierre and Miquelon",
    "Saint Vincent and the Grenadines": "Saint Vincent and the Grenadines",
    "Samoa": "Samoa",
    "San Marino": "San Marino",
    "Sao Tome and Principe": "Sao Tome and Principe",
    "Saudi Arabia": "Saudi Arabia",
    "Senegal": "Senegal",
    "Serbia": "Serbia",
    "Seychelles": "Seychelles",
    "Sierra Leone": "Sierra Leone",
    "Singapore": "Singapore",
    "Sint Maarten (Dutch part)": "Sint Maarten (Dutch part)",
    "Slovakia": "Slovakia",
    "Slovenia": "Slovenia",
    "Solomon Islands": "Solomon Islands",
    "Somalia": "Somalia",
    "South Africa": "South Africa",
    "South Georgia and the South Sandwich Islands": "South Georgia and the South Sandwich Islands",
    "South Sudan": "South Sudan",
    "Spain": "Spain",
    "Sri Lanka": "Sri Lanka",
    "Sudan": "Sudan",
    "Suriname": "Suriname",
    "Svalbard and Jan Mayen": "Svalbard and Jan Mayen",
    "Swaziland": "Swaziland",
    "Sweden": "Sweden",
    "Switzerland": "Switzerland",
    "Syrian Arab Republic": "Syrian Arab Republic",
    "Taiwan, Province of China": "Taiwan, Province of China",
    "Tajikistan": "Tajikistan",
    "Tanzania, United Republic of": "Tanzania, United Republic of",
    "Thailand": "Thailand",
    "Timor-Leste": "Timor-Leste",
    "Togo": "Togo",
    "Tokelau": "Tokelau",
    "Tonga": "Tonga",
    "Trinidad and Tobago": "Trinidad and Tobago",
    "Tunisia": "Tunisia",
    "Turkey": "Turkey",
    "Turkmenistan": "Turkmenistan",
    "Turks and Caicos Islands": "Turks and Caicos Islands",
    "Tuvalu": "Tuvalu",
    "Uganda": "Uganda",
    "Ukraine": "Ukraine",
    "United Arab Emirates": "United Arab Emirates",
    "United Kingdom of Great Britain and Northern Ireland": "United Kingdom of Great Britain and Northern Ireland",
    "United States Minor Outlying Islands": "United States Minor Outlying Islands",
    "United States of America": "United States of America",
    "Uruguay": "Uruguay",
    "Uzbekistan": "Uzbekistan",
    "Vanuatu": "Vanuatu",
    "Venezuela (Bolivarian Republic of)": "Venezuela (Bolivarian Republic of)",
    "Viet Nam": "Viet Nam",
    "Virgin Islands (British)": "Virgin Islands (British)",
    "Virgin Islands (U.S.)": "Virgin Islands (U.S.)",
    "Wallis and Futuna": "Wallis and Futuna",
    "Western Sahara": "Western Sahara",
    "Yemen": "Yemen",
    "Zambia": "Zambia",
    "Zimbabwe": "Zimbabwe",
    "Åland Islands": "Åland Islands"
};

export const DEFAULT_SWITCH_HEIGHT = 20;
export const DEFAULT_SWITCH_WIDTH = 40;

export class UI {

    protected RootStore: RootStore;

    constructor(RootStore: RootStore) {
        this.RootStore = RootStore;
        this.getCountries();
    }

    @persist('object') @observable
    uiState: iUi = {
        countries: [],
        searchFormStep: 1,
        userHasPet: false,
        userHasAdditionalOccupants: false,
        userNeedsParking: false,
        isPromoCodeModalVisible:false,
        isBookingFlowActive:false,
        showVacatePolicyModal: false,
        showStarRatingModel: false,
        showConfirmationModel: false,
        showCancellationPolicyModal: false
    };

    @action.bound
    resetModal() {
        this.uiState.countries = [];
        this.uiState.searchFormStep = 1;
        this.uiState.userHasPet = false;
        this.uiState.userHasAdditionalOccupants = false;
        this.uiState.userNeedsParking = false;
        this.uiState.isPromoCodeModalVisible = false;
        this.uiState.isBookingFlowActive = false;
        this.uiState.showVacatePolicyModal = false;
        this.uiState.showStarRatingModel = false;
        this.uiState.showConfirmationModel = false;
        this.uiState.showCancellationPolicyModal = false;
    }

    // @action.bound
    // toggleIsBookingFlowActive() {
    //     this.uiState.isBookingFlowActive = !this.uiState.isBookingFlowActive;
    // }

    @action.bound
    setBookingFlowActive(bool:boolean) {
        this.uiState.isBookingFlowActive = bool;
    }

    @action.bound
    toggleVacatePolicyModal() {
        this.uiState.showVacatePolicyModal = !this.uiState.showVacatePolicyModal;
    }

    @action.bound
    toggleCancellationPolicyModal() {
        this.uiState.showCancellationPolicyModal = !this.uiState.showCancellationPolicyModal;
    }

    @action.bound
    setCancellationPolicyModal(bool:boolean) {
        this.uiState.showCancellationPolicyModal = bool;
    }

    @action.bound
    setVacatePolicyModal(value:boolean) {
        this.uiState.showVacatePolicyModal = value;
    }

    @action.bound
    setStarRatingModalOpen(value:boolean) {
        //console.log("setStarRatingModalOpen value", value)
        this.uiState.showStarRatingModel = value;
    }

    @action.bound
    setConfirmationModalOpen(value:boolean) {
        this.uiState.showConfirmationModel = value;
    }

    @action.bound
    handleSearchFormBackButton() {
        const currentStep = this.uiState.searchFormStep;
        if (this.uiState.searchFormStep !== 0) {
            this.uiState.searchFormStep = (currentStep - 1);
        }
    }

    @action.bound
    handleSearchFormNextButton() {
        const currentStep = this.uiState.searchFormStep;
        if (this.uiState.searchFormStep !== 0) {
            this.uiState.searchFormStep = (currentStep + 1);
        }
    }

    @action.bound
    handleToggleUserHasPet() {
        this.uiState.userHasPet = !this.uiState.userHasPet;
        if (this.uiState.userHasPet) {
            //this.RootStore.bookingModel.addPet();
        } else {
            this.RootStore.bookingModel.clearPets();
        }
    }

     /**
   * Toggle visibility for promo code modal
   */
    @action.bound
    togglePromoCodeVisibility() {
        this.uiState.isPromoCodeModalVisible = !this.uiState.isPromoCodeModalVisible;
    }

    @action.bound
    handleToggleHasAdditionalOccupants() {
        this.uiState.userHasAdditionalOccupants = !this.uiState.userHasAdditionalOccupants;
    }
    // this.bookingState.number_parking_spots = 0;
    // this.bookingState.charge_parking_to_guest = false;
    @action.bound
    handleToggleUserNeedsParking() {
        this.uiState.userNeedsParking = !this.uiState.userNeedsParking;
    }

    @action.bound
    handleSwitch = (key: string) => {
        switch (key) {
            case "pets": {
                this.handleToggleUserHasPet()
                break;
            }
            case "occupants": {
                this.handleToggleHasAdditionalOccupants();
                break;
            }
            case "parking": {
                this.handleToggleUserNeedsParking();
                break;
            }
        }
    }

    /**
    * UI getCountries
    **/
    getCountries() {
        return UserAgent.getCountries().then((response) => {
            runInAction(() => {
                this.uiState.countries = response.data.data;
            });
        }
        )
            .catch(
                (error) => {
                    console.log(error);
                }
            )
    }

    @action.bound
    setUserHasPets(bool) {
        this.uiState.userHasPet = bool;
    }

    @action.bound
    setUserNeedsParking(bool) {
        this.uiState.userNeedsParking = bool;
    }

    @action.bound
    setUserHasAdditionalOccupants(bool) {
        this.uiState.userHasAdditionalOccupants = bool;
    }
}

export default UI;