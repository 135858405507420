
import RootStore from './RootStore';
import User from './User';

// We refactored this so we can use mobx persist
// We moved the PropertyRootStore to be RootStoire and moved it into the the root of the models
const appStore = {
  RootStore: RootStore,
  User: User,
}

export  default appStore;
