import {observable, action, computed, runInAction} from "mobx";
import {iUser} from "./interfaces";
import {UserAgent} from './agent';

export class User {
  @observable
    userData: iUser = {
        // login: 'mkerr@nomadtemphousing.com',
        // pass: 'password!1',
        login: 'InstantBook@nomadtemphousing.com',
        pass: 'InstantWebsearching!2019',
        token: localStorage.getItem('token') || '',
    };

  login(){
    return UserAgent.login(this.userData.login, this.userData.pass)
      .then(
        (response) =>{
          localStorage.setItem('token', response.data.access_token);
          runInAction(()=>{
            this.userData.token = response.data.access_token;
          });
        }
      )
      .catch(
        (error) => {
          console.log(error);
        }
      )
  }

  /**
   * User log out function
   */
  @action.bound
    logOut(){
      this.userData.token = '';
    }
}

export default new User();