import { routes } from "./routes";
import nomadXHR from "../adapter";
import { iCustomizeData, iGuestInfoData, iBillingInfoData, iRentalAgreementData } from "./interfaces";

export const BookingAgent = {
    get: () => {

    },
    misc: {
        booknow: ( data: { selectedOption: any; customizations: Array<string>, search_reference: any, promoCode:any}) => nomadXHR({
            method: "post",
            url: routes.ajax.booknow + data.selectedOption.id,
            data
        }),
        guestInfo: (data: {selectedOption: any, guestUser: iGuestInfoData, customizations: Array<string>, bookingId: number}) => nomadXHR({
            method: "post",
            url: routes.ajax.guestInfo + data.selectedOption.id,
            data
        }),
        rentalAgreement: (data: {selectedOption: any, guestUser: iGuestInfoData, customizations: Array<string>, rentalAgreementData: iRentalAgreementData, bookingId: number}) => nomadXHR({
            method: "post",
            url: routes.ajax.rentalAgreement + data.selectedOption.id,
            data
        }),
        submitPaymentDatails: (data: {selectedOption: any, guestUser: iGuestInfoData, customizations: Array<string>, rentalAgreementData: iRentalAgreementData, bookingId: number}) => nomadXHR({
            method: "post",
            url: routes.ajax.submitPaymentDatails + data.selectedOption.id,
            data
        }),
        getConvertedCurrency: (data: { amount: any, from: any , to: any }) => nomadXHR({ 
            method: "post",
            url: routes.ajax.getConvertedCurrency ,
            data
        }),
        getTotalWithTaxes: (
            data: { 
                inventory_id: any, 
                arrival_date:any, 
                departure_date: any, 
                additional_fees: any, 
                inventory_rate_id: any,
                additional_adults: any,
                num_children: any,
                promo_code: any,
                is_hotel_option: any,
                hotel_option_id: any
                }) => nomadXHR({ 
            method: "post",
            url: routes.ajax.getTotalWithTaxes ,
            data
        })

    }
}
