// here we will store axios configuration for different domains
import axios, {AxiosError, AxiosResponse} from 'axios';
import User from './User';



const xhr = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  //baseURL: 'https://dev-api.nomadtemphousing.com/api',
  //baseURL: 'http://nomadapi.test/api/',
  timeout: 150000,
  headers: {}
});

// if(User.userData.token){
//   xhr.defaults.headers['AUTHORIZATION'] = `Bearer ${User.userData.token}`;
// } else {
//     xhr.post('/login', {
//       email: User.userData.login,
//       password: User.userData.pass
//     })
//     .then(() => {
//         xhr.defaults.headers['AUTHORIZATION'] = `Bearer ${User.userData.token}`;
//       }
//     )
//     .catch(
//       (err: AxiosError) => {
//         //console.log('Sorry something went wrong. Can\'t login with provided credentials. Error data =>', err.response );
//       }
//     );
// }

//
// Add a request interceptor
xhr.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// number of attempts to request server
let reqNumber = 5;

// Add a response interceptor
xhr.interceptors.response.use(
  (response: AxiosResponse) =>{ reqNumber=5; return response; },
  (error: AxiosError) => {
//   if (error.config && error.response && error.response.status === 401 && reqNumber) {
//     return User.login().then(() => {
//       --reqNumber;
//       xhr.defaults.headers['AUTHORIZATION'] = error.config.headers['AUTHORIZATION'] = `Bearer ${User.userData.token}`;
//       return xhr.request(error.config);
//     });
//   }
//   return Promise.reject(error);
});

export default xhr;
