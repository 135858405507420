import { routes } from "./routes";
import nomadXHR from "../adapter";
import { iRequestInfoParams, iRequestInfoData, iRequestState } from "./interfaces";

export const InquiryAgent = {
    get: () => {

    },
    misc: {
        submit:( property_id: number, data: any) => {
            return nomadXHR({
                method: 'post',
                url: routes.ajax.submitInquiry + property_id,
                data: data,
            })
        }
    }
}