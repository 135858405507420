import './assets/styles/index.scss';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';

import { HashRouter, Switch, Route, BrowserRouter } from 'react-router-dom';
import { Provider } from 'mobx-react';
import { configure, autorun } from "mobx";
import DevTools from 'mobx-react-devtools'
import GaurdedRoute from "./routes/GuardedRoute/GuardedRoute";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import appState from './models';

const Main = React.lazy(() => import('./routes/Main'));
const Properties = React.lazy(() => import('./routes/Properties'));
const Property = React.lazy(() => import('./routes/Property'));
const RequestInfo = React.lazy(() => import('./routes/RequestInfo'));
const Booking = React.lazy(() => import('./routes/Booking'));
const About = React.lazy(() => import('./routes/About'));
const Housing = React.lazy(() => import('./routes/Housing'));
const Privacy = React.lazy(() => import('./routes/Privacy'));
const Legal = React.lazy(() => import('./routes/Legal'));
const News = React.lazy(() => import('./routes/News'));
const StripeLandingPage = React.lazy(() => import('./routes/LandingPages/StripeLandingPage'));
const BookingTransactionSuccess = React.lazy(() => import('./components/Booking/BookingTransactionSuccess'));
const AboutForMobileApp = React.lazy(() => import('./routes/AboutForMobileApp'));
import * as serviceWorker from './serviceWorker';

configure({ enforceActions: "always" });

const App = () => (
    <Provider {...appState}>
        <BrowserRouter>
            <Suspense fallback="">
                <Switch>
                    <Route onUpdate={() => window.scrollTo(0, 0)} exact path="/" component={Main} />
                    <Route onUpdate={() => window.scrollTo(0, 0)} exact path="/about/:page" component={About} />
                    <Route onUpdate={() => window.scrollTo(0, 0)} exact path="/housing/:page" component={Housing} />
                    <Route onUpdate={() => window.scrollTo(0, 0)} exact path="/privacy" component={Privacy} />
                    <Route onUpdate={() => window.scrollTo(0, 0)} exact path="/legal" component={Legal} />
                    <Route onUpdate={() => window.scrollTo(0, 0)} exact path="/properties" component={Properties} />
                    <Route onUpdate={() => window.scrollTo(0, 0)} exact path="/marketing/:id" component={Property} />
                    <Route onUpdate={() => window.scrollTo(0, 0)} exact path="/marketing_hotel_option/:id/:hotel_option_id" component={Property} />
                    <Route onUpdate={() => window.scrollTo(0, 0)} exact path="/requestinfo/:step/:id" component={RequestInfo} />
                    <Route onUpdate={() => window.scrollTo(0, 0)} exact path="/booking/:step/:id?" component={Booking} />
                    <Route onUpdate={() => window.scrollTo(0, 0)} exact path="/news/:step/:id?" component={News} />
                    {/* <GaurdedRoute path="/booking/:step/:id?" component={Booking} auth={appState.RootStore.uiModel.uiState.isBookingFlowActive} /> */}
                    <Route onUpdate={() => window.scrollTo(0, 0)} exact path="/transaction_success" component={BookingTransactionSuccess} />
                    <Route onUpdate={() => window.scrollTo(0, 0)} exact path="/stripe-internship" component={StripeLandingPage} />
                    <Route onUpdate={() => window.scrollTo(0, 0)} exact path="/aboutmobile/:page" component={AboutForMobileApp} />
                </Switch>
                {/* <DevTools/> */}
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                />
            </Suspense>
        </BrowserRouter>
    </Provider>
);

autorun(() => {
    ReactDOM.render(<App />, document.getElementById('root'));
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
