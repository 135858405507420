import {
  iQueryParams,
  iAmenities,
  iApartmentType,
  iApartmentsSorting,
  iRating,
  iSearchRate,
  iWalkScoreParams,
  iGetNeighborsParams_1,
  iGetNeighborsParams_2,
  iNumberOfGuests,
  iSearchRequestParams, iDistance
} from "./interfaces";
import {iProperty} from "../interfaces";
import moment from 'moment';
import nomadXHR from '../../adapter';
import routes from './routes';


export const Properties = {
  getAll: (data: { queryParams: any, bookingParams: any, searchParams:any}) => nomadXHR({
      method: 'post',
      url: routes.ajax.instantSearch,
      data
  }),
  emailRequest: (search_reference: string) => nomadXHR({
    method: 'post',
    url: routes.ajax.emailAvailabilities,
    params: { search_reference } || {},
  }),
  validateCode: (promoCode: string) => nomadXHR({
    method: 'post',
    url: routes.ajax.validateCode,
    params: { promoCode } || {},
  }),
  get: (id: number) => nomadXHR({
    method: 'get',
    url: routes.ajax.propertySingle + id,
  }),
  create(data: iProperty){},
  update(id: number, data: iProperty){},
  delete(id: number){},
  misc: {
    getAmenities: () => {
      return nomadXHR({
        method: 'get',
        url: '/metadata/list_amenities'
      })
        .then((res) => {
           //console.log(">>>>>>>>>>>>>>>>>>>>>getAmenities res.data", res.data);
          return res.data;
        })
    },
    getApartmentTypes: () => {
      // return nomadXHR({
      //   method: 'get',
      //   url: '/metadata/list_unit_types'
      // });
      return [
        {
          id: 1,
          name: 'Studio',
          selected: false,
          disabled: false
        },
        {
          id: 2,
          name: '1 Bedroom',
          selected: false,
          disabled: false
        },
        {
          id: 3,
          name: '2 Bedroom',
          selected: false,
          disabled: false
        },
        {
          id: 4,
          name: '3 Bedroom',
          selected: false,
          disabled: false
      },
    ]
    },
    getNumberOfGuests(): iNumberOfGuests[] {
      return [
        {
          id: 1,
          name: '1 Guest',
          value: 1,
          selected: false,
        },
        {
          id: 2,
          name: '2 Guests',
          value: 2,
          selected: false,
        },
        {
          id: 3,
          name: '3 Guests',
          value: 3,
          selected: false,
        },
        {
          id: 4,
          name: '4 Guests',
          value: 4,
          selected: false,
        },
        {
          id: 5,
          name: '5 Guests',
          value: 5,
          selected: false,
        },
        {
          id: 6,
          name: '6 Guests',
          value: 6,
          selected: false,
        },
        {
          id: 7,
          name: '7 Guests',
          value: 7,
          selected: false,
        },
        {
          id: 8,
          name: '8 Guests',
          value: 8,
          selected: false,
        },
      ];
    },
    getSortingOptions(): iApartmentsSorting[] {
      return [
        // {
        //   id: 1,
        //   type: 'rate',
        //   name: 'Rate - Low to High',
        //   dir: 'asc',
        //   selected: false
        // },
        // {
        //   id: 2,
        //   type: 'rate',
        //   name: 'Rate - High to low',
        //   dir: 'desc',
        //   selected: false
        // },
        {
          id: 3,
          type: 'distance',
          name: 'Distance - Low to High',
          dir: 'asc',
          selected: false
        },
        {
          id: 4,
          type: 'distance',
          name: 'Distance - High to low',
          dir: 'desc',
          selected: false
        },
        {
          id: 5,
          type: 'admin_rating',
          name: 'Star Rating - Low to High',
          dir: 'asc',
          selected: false
        },
        {
          id: 6,
          type: 'admin_rating',
          name: 'Star Rating - High to low',
          dir: 'desc',
          selected: false
        }
      ];
    },
    getRatingOptions(): iRating[] {
      return [
        // {
        //   id: 1,
        //   value: 1,
        //   name: '1-Star Rating',
        //   selected: false
        // },
        // {
        //   id: 2,
        //   value: 2,
        //   name: '2-Stars Rating',
        //   selected: false
        // },
        {
          id: 3,
          value: 3,
          name: '3-Stars Rating and higher',
          selected: false
        },
        {
          id: 4,
          value: 4,
          name: '4-Stars Rating and higher',
          selected: false
        },
        {
          id: 5,
          value: 5,
          name: '5-Stars Rating and higher',
          selected: false
        },
      ];
    },
    getRateRanges(): iSearchRate {
        return {
        from: 0,
        to: 1000,
        value: {
          min: 0,
          max: 1000
        }
      }
    },
    getDistance(): iDistance {
      return {
        from: 0,
        to: 25,
        value: {
          min: 0,
          max: 25,
        }
      }
    },
    getWalkScore: (params: iWalkScoreParams) => nomadXHR({
      method: 'get',
      url: routes.ajax.propertySingle + 'walkscore',
      params: params,
    }),
  },

}
