import { observable, action, computed, runInAction } from "mobx";
import { Properties } from "./agent";
import { iProperty, iNeighbor } from "./interfaces";
import { mockOption } from "./Search/mockStoreData";
import moment from 'moment';
import { Moment } from "moment";
import { iRateRange } from "./Search/interfaces";
import { RootStore } from "../RootStore";
import { persist } from 'mobx-persist'
// @todo FIXME dirty hack needs to be removed

function generateRandomPrice(min: number, max: number) {
  return (Math.round(Math.random() * (max - min) + min) * 10) / 10; // JS... old good JS
}

// @todo FIXME dirty hack needs to be removed


export class SingleProperty {

  protected RootStore:RootStore;

  constructor(RootStore: RootStore){
    this.RootStore = RootStore;
  }

  @persist('list') @observable
  neighbors: iNeighbor[] = [];

  @persist @observable
  selectedIndex: number | null = 0;

  @persist('object') @observable
  selectedOption: {} | null = 0;

  @persist('object') @observable
  property: iProperty = {
    id: 0,
    lat: '',
    long: '',
    rate_ranges: [],
    options: [],
    amenities: [],
    admin_rating: 0,
    name: '',
    distance: 0,
    address: '',
    description: '',
    walkscore: 0,
    area_description: '',
    pets_description: '',
    property_documents: null,
    active_inventory: false,
    property_taxes: [],
    availabilities: [],
    isBookable:[],
    has_selected_bedroom_type: true
  };

  @persist('object') @observable
  ui = {
    showLightBox: false,
    lightBoxIndex: 0,
    isPromoCodeModalVisible: false,
    rateRangeSelected: false,
  }

  getOptionsStartTime: number;

  @action
  resetModel() {
   
    this.property.id =  0;
    this.property.lat = '';
    this.property.long ='';
    this.property.rate_ranges = [];
    this.property.options = [];
    this.property.amenities = [];
    this.property.admin_rating = 0;
    this.property.name = '';
    this.property.distance = 0;
    this.property.address = '';
    this.property.description = '';
    this.property.walkscore = 0;
    this.property.area_description = '';
    this.property.pets_description = '';
    this.property.property_documents = null;
    this.property.active_inventory = false;
    this.property.property_taxes = [];
    this.property.availabilities = [];
    this.property.isBookable = []
    this.ui.showLightBox = false
    this.ui.lightBoxIndex = 0
    this.ui.isPromoCodeModalVisible = false
    this.ui.rateRangeSelected = false;

    this.selectedIndex = 0
    this.selectedOption = {}
}

  @action.bound
  getPropertyRequest(id: number) {

    
    //console.log( "this.propRootStore.searchModel.filters.arrival_date", this.propRootStore.searchModel.filters.arrival_date);
    //console.log( "this.propRootStore.searchModel.filters.departure_date", this.propRootStore.searchModel.filters.departure_date);
    //console.log( "this.propRootStore.searchModel.filters.promoCode", this.propRootStore.searchModel.filters.promoCode);

    const searchFilters = this.RootStore.searchModel.filters;
    const arrival = this.RootStore.searchModel.filters.arrival_date;
    const departure =  this.RootStore.searchModel.filters.departure_date;
    const promoCode = this.getPromoCode;
    const webref = searchFilters.search_reference;
    
    //console.log("arrival", arrival);
    //console.log("departure", departure);
    //console.log("promoCode", promoCode);
    
    return Properties.get(id, this.getArrivalDate ,  this.getDepartureDate , this.getPromoCode, searchFilters.search_reference )
      .then(resp => {

        //console.log("Single Property getPropertyRequest resp", resp);
        const property = resp.data.data;
        runInAction(() => {
          this.property = property;
          this.property.availabilities = property.isBookable || [];
          this.property.selectedOption = this.property.availabilities[this.selectedIndex];
          this.RootStore.bookingModel.clearAdditionalFees();

          this.RootStore.bookingModel.setCustomizations(false);
          this.setCustomFees(this.property.selectedOption);
          //this.RootStore.bookingModel.ui.userAppliedCustomizations = false;
          //this.RootStore.bookingModel.ui.userDeniedCustomization = false;
          //   const rateRanges = property.rate_ranges || [];
          //   if (rateRanges.length) {
          //     this.selectRateRange(property.rate_ranges[0].id);
          //   }

          //Here is where we need to puttogether the fees for the customize window.
        //   return !!this.singlePropertyStore.property.isBookable ?
        //   !!this.singlePropertyStore.property.availabilities[this.singlePropertyStore.selectedIndex].custom_fees &&
        //   this.singlePropertyStore.property.availabilities[this.singlePropertyStore.selectedIndex].custom_fees : 
        //   this.singlePropertyStore.property.property_fees;
        });

        return property;
      })
      .catch(err => {
        console.log('[get property error]', err);
      })
  }

  @action.bound
  getPropertyHotelRequest(id: number, hotel_option_id: number) {


    //console.log( "this.propRootStore.searchModel.filters.arrival_date", this.propRootStore.searchModel.filters.arrival_date);
    //console.log( "this.propRootStore.searchModel.filters.departure_date", this.propRootStore.searchModel.filters.departure_date);
    //console.log( "this.propRootStore.searchModel.filters.promoCode", this.propRootStore.searchModel.filters.promoCode);

    const searchFilters = this.RootStore.searchModel.filters;
    const arrival = this.RootStore.searchModel.filters.arrival_date;
    const departure =  this.RootStore.searchModel.filters.departure_date;
    const promoCode = this.getPromoCode;
    const webref = searchFilters.search_reference;

    //console.log("arrival", arrival);
    //console.log("departure", departure);
    //console.log("promoCode", promoCode);

    return Properties.getHotel(id, this.getArrivalDate ,  this.getDepartureDate , this.getPromoCode, searchFilters.search_reference, hotel_option_id )
        .then(resp => {

          //console.log("Single Property getPropertyRequest resp", resp);
          const property = resp.data.data;
          runInAction(() => {
            this.property = property;
            this.property.availabilities = property.isBookable || [];
            this.property.selectedOption = this.property.availabilities[this.selectedIndex];
            this.RootStore.bookingModel.clearAdditionalFees();

            this.RootStore.bookingModel.setCustomizations(false);
            this.setCustomFees(this.property.selectedOption);
            //this.RootStore.bookingModel.ui.userAppliedCustomizations = false;
            //this.RootStore.bookingModel.ui.userDeniedCustomization = false;
            //   const rateRanges = property.rate_ranges || [];
            //   if (rateRanges.length) {
            //     this.selectRateRange(property.rate_ranges[0].id);
            //   }

            //Here is where we need to puttogether the fees for the customize window.
            //   return !!this.singlePropertyStore.property.isBookable ?
            //   !!this.singlePropertyStore.property.availabilities[this.singlePropertyStore.selectedIndex].custom_fees &&
            //   this.singlePropertyStore.property.availabilities[this.singlePropertyStore.selectedIndex].custom_fees :
            //   this.singlePropertyStore.property.property_fees;
          });

          return property;
        })
        .catch(err => {
          console.log('[get property error]', err);
        })
  }

  @action
  setCustomFees(selectedOption: any) {
      if (!!selectedOption && !!selectedOption.custom_fees) {
            this.RootStore.bookingModel.setAdditionalFees( selectedOption.custom_fees);
      }
  }

  @action.bound
  clearPropertyId() {
    this.property.id = null;
  }

  @action.bound
  setOptionsFromList(options: any[]) {
    this.property.options = options;
  }

  @action.bound
  getOptionsForProperties(params: { lead_id: number, property_id: number }) {
    return Properties.getOptions(params).then(resp => {
      if ((!Array.isArray(resp.data.data) || !resp.data.data || !resp.data.data.length) && (!this.property.options || !this.property.options.length) && Date.now() - this.getOptionsStartTime < 240000) {
        //   setTimeout( () => {
        //     this.getOptionsForProperties(params);
        //   }, 10000 )
      }

      // runInAction(() => {
      //   this.property.options = resp.data.data;
      // });
    }
    )
      .catch(e => { console.log('[ error getting options for single property ]', e); })
  }

  /**
   * Get single property object
   * @param {number} id
   */
  @action.bound
  getSingleProperty(id: number) {
    return this.getPropertyRequest(id).then(property => {
      const arrival = this.getArrivalDate;
      const departure =  this.getDepartureDate;
      
      const requestLeadIdParams = {
        arrival_date: arrival,
        departure_date: departure ,
        city_name: property.city,
        state_name: property.state_name,
        country_name: property.country_name,
        lat: property.lat,
        long: property.long,
        bedroom: 'Studio, 1 Bedroom, 2 Bedroom, 3 Bedroom',
      }

      Properties.getWalkScore({ lat: property.lat, long: property.long })
        .then(getWalkScoreResp => {

          runInAction(() => {
            this.property.walkscore = getWalkScoreResp.data.data.walkscore;
          });
        })
        .catch(e => {
          console.log('[walkscores returned error]', e);
        });

      Properties.getNeighborhoods({ property_id: id, limit: 10 })
        .then((getNeighborhoodsResp) => {
          runInAction(() => {
            this.neighbors = getNeighborhoodsResp.data.data;
          });
        })
        .catch(e => {
          console.log('[getNeighborhoods] error occurred', e);
        })

        const promoCode = this.getPromoCode;

      // Properties.getPropertyAvailabilities({ property_id: id, start_date: arrival, end_date: departure, promoCode: promoCode || moment().format('Y-MM-DD') })
      //   .then((getPropertyAvailabilities) => {
      //     runInAction(() => {
      //       this.property.availabilities = getPropertyAvailabilities.data.data;
      //       this.selectedIndex = 0;
      //     });
      //   })
      //   .catch(e => {
      //     console.log('[getPropertyAvailabilities] error occurred', e);
      //   })
    })
      .catch(e => { console.log('[ error getting single property ]', e); })


    //       Properties.getNeighborhoods({property_id: id, limit: 50})
    //         .then((getNeighborhoodsResp) => {
    //           runInAction(() => {
    //             this.neighbors = getNeighborhoodsResp.data.data;
    //           });
    //         })
    //         .catch( e => {
    //           console.log('[getNeighborhoods] error occurred');
    //         })
    //     })
    //     .catch(err => {
    //       console.log('[get property error]');
    //     })
  }

  /**
   * Get single property object
   * @param {number} id
   */
  @action.bound
  getSingleHotelProperty(id: number, hotel_option_id: number) {
    return this.getPropertyHotelRequest(id, hotel_option_id).then(property => {
      const arrival = this.getArrivalDate;
      const departure =  this.getDepartureDate;

      const requestLeadIdParams = {
        arrival_date: arrival,
        departure_date: departure ,
        city_name: property.city,
        state_name: property.state_name,
        country_name: property.country_name,
        lat: property.lat,
        long: property.long,
        bedroom: 'Studio, 1 Bedroom, 2 Bedroom, 3 Bedroom',
      }

      Properties.getWalkScore({ lat: property.lat, long: property.long })
          .then(getWalkScoreResp => {

            runInAction(() => {
              this.property.walkscore = getWalkScoreResp.data.data.walkscore;
            });
          })
          .catch(e => {
            console.log('[walkscores returned error]', e);
          });

      Properties.getNeighborhoods({ property_id: id, limit: 10 })
          .then((getNeighborhoodsResp) => {
            runInAction(() => {
              this.neighbors = getNeighborhoodsResp.data.data;
            });
          })
          .catch(e => {
            console.log('[getNeighborhoods] error occurred', e);
          })

      const promoCode = this.getPromoCode;

      // Properties.getPropertyAvailabilities({ property_id: id, start_date: arrival, end_date: departure, promoCode: promoCode || moment().format('Y-MM-DD') })
      //   .then((getPropertyAvailabilities) => {
      //     runInAction(() => {
      //       this.property.availabilities = getPropertyAvailabilities.data.data;
      //       this.selectedIndex = 0;
      //     });
      //   })
      //   .catch(e => {
      //     console.log('[getPropertyAvailabilities] error occurred', e);
      //   })
    })
        .catch(e => { console.log('[ error getting single property ]', e); })


    //       Properties.getNeighborhoods({property_id: id, limit: 50})
    //         .then((getNeighborhoodsResp) => {
    //           runInAction(() => {
    //             this.neighbors = getNeighborhoodsResp.data.data;
    //           });
    //         })
    //         .catch( e => {
    //           console.log('[getNeighborhoods] error occurred');
    //         })
    //     })
    //     .catch(err => {
    //       console.log('[get property error]');
    //     })
  }

  @action.bound
  selectCurrentSelectedProperty(id: number) {
    //console.log("selectRateRange id", id);
    // console.log("selectRateRange selectedInstance", selectedInstance);
    this.selectedIndex = id;
    //console.log("this.selectedIndex", this.selectedIndex);
    this.RootStore.bookingModel.clearAdditionalFees();
    this.RootStore.bookingModel.setCustomizations(false);
    this.property.selectedOption = this.property.availabilities[this.selectedIndex];
    this.setCustomFees(this.property.selectedOption);
  }

  @action.bound
  setRateRanges(rate_ranges: iRateRange[]) {
    this.property.rate_ranges = rate_ranges;
  }

  /**
   * Toggle visibility for promo code modal
   */
  @action.bound
  togglePromoCodeVisibility() {
    this.ui.isPromoCodeModalVisible = !this.ui.isPromoCodeModalVisible;
  }

  /**
   * Toggle lightbox
   * @param {string} index
   */
  @action.bound
  openLightBox(index?: number) {
    this.ui.showLightBox = true;
    this.ui.lightBoxIndex = index || 0;
  }

  /**
   * Close lightbox
   */
  @action.bound
  closeLightBox() {
    this.ui.showLightBox = false;
  }

  @computed
  get getArrivalDate() {
    const tempFilters = {...this.RootStore.searchModel.filters};
    //console.log("getArrivalDates this.RootStore.searchModel.filters", this.RootStore.searchModel.filters);
    //const arrival = !!tempFilters && tempFilters.arrival_date && tempFilters.arrival_date.format('Y-MM-DD') || moment().format('Y-MM-DD');
    const arrival = !!tempFilters && tempFilters.arrival_date && tempFilters.arrival_date;
    return moment(arrival).format('Y-MM-DD') 
  }

  @computed
  get getDepartureDate() {
    const tempFilters = {...this.RootStore.searchModel.filters};
    //const departure = !!tempFilters && tempFilters.departure_date && tempFilters.departure_date.format('Y-MM-DD') || moment().add(30, "days").format('Y-MM-DD');
    const departure = !!tempFilters && tempFilters.departure_date && tempFilters.departure_date;
    return moment(departure).format('Y-MM-DD') 
  }

  @computed
  get getPromoCode() {
    const searchFilters = this.RootStore.searchModel.filters;
    const promoCode = !!searchFilters && searchFilters.promoCode || "";
    return promoCode;
  }

}
