import axios from '../adapter';

const request = Object.freeze({
  getCountries: '/metadata/list_countries',
});

export const UserAgent = {
  getCountries: () => axios({
    method: 'get',
    url: request.getCountries,
  }),
}