export const routes = Object.freeze({
    path: {
        customize: "/booking/customize",
        guestInfo: "/booking/guest_information",
        paymentDetails: "/booking/payment_details",
        rentalAgreement: "/booking/rental_agreement",
        bookingConfirmed: "/booking/confirmed",
        getConvertedCurrency: "/utilities/currency/convert"
    },
    ajax: {
        booknow: "/booking/book_now/",
        guestInfo: "/booking/guest_information/",
        billingInfo: "/booking/billing_information/",
        submitPaymentDatails: "/booking/payment_details/",
        rentalAgreement: "/booking/rental_agreement/",
        bookingConfirmed: "/booking/confirmed/",
        getConvertedCurrency: "/utilities/currency/convert",
        getTotalWithTaxes: "/self-serve/get_total_with_taxes"
    }
});
